import React from "react"
import { Flex } from "@chakra-ui/layout"
import { Stat, StatLabel, StatHelpText, StatNumber } from "@chakra-ui/react"
import { colorPalette } from "../../common/constants"
import { nodeData } from "../../common/interfaces"

type propsType = {
  data: nodeData
}

export const CustomNode = (props: propsType) => {
  const { data } = props

  const { color, name, binString, count, updateNodes, id, selected, label } =
    data
  const borderColor = selected ? "yellow" : colorPalette[0]

  const justify = label ? "space-around" : "center"

  return (
    <Flex
      direction="column"
      alignItems="center"
      p="10px"
      outline={`4px solid ${borderColor}`}
      background={color}
      borderRadius="10px"
      opacity="0.95"
      fontSize="small"
      w="190px"
      onClick={() => {
        updateNodes && updateNodes(id)
      }}
    >
      <Stat size="sm">
        <StatLabel>{name}</StatLabel>
        <Flex mb={0} justify={justify}>
          <StatHelpText>{binString}</StatHelpText>
          <StatHelpText>{label || ""}</StatHelpText>
        </Flex>

        <StatNumber textAlign="center">{count}</StatNumber>
      </Stat>
    </Flex>
  )
}

import React, { useEffect, useReducer } from "react"
import { ChakraProvider, Heading, CSSReset, Flex, Box } from "@chakra-ui/react"
import { reducer } from "../components/common/reducer"
import { initial, windowExists } from "../components/common/constants"
import customTheme from "../components/common/theme"
import { MainDiv } from "../components/MainDiv"
import { Header } from "../components/Header"
import { displayObject } from "../components/displayObject"
import { fetchCharacteristics } from "../components/LandingPage/fetchCharacteristics"
import { Toaster } from "../components/common/Toaster"
import { BottomBar } from "../components/common/BottomBar"
import { SEO } from "../components/seo"
import { ModalHandler } from "../components/common/ModalHandler"

export default function IndexComponent() {
  if (!windowExists) {
    return <Heading m={4}>Loading...</Heading>
  }

  const item = sessionStorage.getItem("npd_state")

  const inheritedState = item ? JSON.parse(item) : initial

  const [state, dispatch] = useReducer(reducer, {
    ...inheritedState,
    loading: false
  })

  const target = displayObject.find(({ name }) => name === state.show)
  if (!target) return null

  const { component } = target

  useEffect(() => {
    if (state.availableCharacteristics?.length) return
    fetchCharacteristics(dispatch)
  }, [])

  console.info(state)

  const standardProps = { state, dispatch }

  return (
    <ChakraProvider theme={customTheme}>
      <CSSReset />
      <MainDiv>
        <Flex direction="column" h="100%" w="100%" alignItems="center">
          <Header />
          <Box
            id="component_wrapper"
            h="100%"
            w="100%"
            overflow="hidden"
            p="1em"
          >
            {component(standardProps) || null}
          </Box>

          <BottomBar {...standardProps} />
        </Flex>
      </MainDiv>
      <SEO />
      <Toaster dispatch={dispatch} message={state.message} />
      <ModalHandler {...standardProps} />
    </ChakraProvider>
  )
}

import { damDataType } from "../Results/GroupCharacteristics/types"
import { HeaderFormatter } from "./HeaderFormatter"

type propTypes = { selectedDams: damDataType }

export const getColumns = ({ selectedDams }: propTypes) => {
  if (!selectedDams.length) return []

  return Object.keys(selectedDams[0]).map((key) => {
    return {
      dataField: key,
      headerClasses: `header_${key}`,
      text: key,
      sort: true,
      sortCaret: (order: string) => {
        return order === "asc" ? "▼" : "▲"
      },
      headerFormatter: HeaderFormatter
    }
  })
}

import React from "react"
import { dispatchType, standardProps, stateType } from "../common/interfaces"
import { DragDropContext } from "react-beautiful-dnd"
import { Box, Flex } from "@chakra-ui/react"
import DroppableInstance from "./SelectedDroppableInstance"
import { useCharacteristics } from "./useCharacteristics"
import UnselectedDroppableInstance from "./UnselectedDroppableInstance"
import SelectedDroppableInstance from "./SelectedDroppableInstance"

export default function DragCharacteristics(props: standardProps) {
  const { categories, setCategories, onDragEnd } = useCharacteristics(props)

  return (
    <Box h="100%" w="100%" overflow="hidden">
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex h="100%" w="100%" justify="space-around">
          <UnselectedDroppableInstance
            list={categories.unselected}
            {...props}
          />
          <SelectedDroppableInstance
            setCategories={setCategories}
            list={categories.selected}
            {...props}
          />
        </Flex>
      </DragDropContext>
    </Box>
  )
}

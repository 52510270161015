import { characteristicsInterface } from "./types"
import {
  attributeType,
  dispatchType,
  schemaType,
  stateType
} from "./interfaces"

export function sortObjects(keyword: string, type: "strings" | "numbers") {
  return function sortFunction(a: any, b: any) {
    const aValue: string =
      type === "strings" ? a[keyword].toLowerCase() : +a[keyword]
    const bValue: string =
      type === "strings" ? b[keyword].toLowerCase() : +b[keyword]
    if (aValue > bValue) return 1
    if (aValue < bValue) return -1
    return 0
  }
}

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const getAllIds = (schemaData: schemaType) => {
  const allFeatureSet = new Set<number>()
  const selectedNodes = schemaData.nodes.filter(({ data }) => data.selected)
  selectedNodes.forEach((item) => {
    item.data.features.forEach((feature) => allFeatureSet.add(feature))
  })
  return Array.from(allFeatureSet)
}

export const getAttributeList = (state: stateType): attributeType[] => {
  const emptyDataSources: characteristicsInterface[] = []
  const chars: characteristicsInterface[] =
    state.availableCharacteristics.reduce((acc, char) => {
      const exists = acc.find(({ name }) => name === char.name)
      if (!exists) {
        acc.push({
          ...char,
          name: char.name,
          theme: char.theme
        })
      }

      return acc
    }, emptyDataSources)

  const unsortedAttributeList: attributeType[] = chars.map((char) => {
    return {
      ...char,
      columnName: char.column,
      humanName: char.name,
      enabled: true,
      units: char.units || "Unknown"
    }
  })

  return unsortedAttributeList.sort(sortObjects("humanName", "strings"))
}

export const closeModal = (dispatch: dispatchType) =>
  dispatch({
    type: "setModal",
    payload: { modal: "", modalMessage: "" }
  })

export const extractDataSource = (
  array: characteristicsInterface[],
  targetColumn: string
) => {
  const characteristic = array.find(({ column }) => column === targetColumn)!

  return { characteristic }
}

export const getDataSource = (state: stateType, targetColumn: string) => {
  const { availableCharacteristics, filters, originalFilters } = state

  const { characteristic: targetCharacteristic } = extractDataSource(
    availableCharacteristics,
    targetColumn
  )
  const { characteristic: filterCharacteristic } = extractDataSource(
    filters,
    targetColumn
  )
  const { characteristic: defaultCharacteristic } = extractDataSource(
    originalFilters,
    targetColumn
  )

  const defaultValues = defaultCharacteristic.bins[0]
  const userValues = filterCharacteristic.bins.find(
    ({ userDefined }) => userDefined
  )

  const valuesRaw = userValues || defaultValues
  const defaults = defaultValues.values.map(({ value }) => +value)
  const values = valuesRaw.values.map(({ value }) => +value)

  const firstDefault = defaults[0]
  const lastDefault = defaults[defaults.length - 1]

  return {
    targetCharacteristic,

    filterCharacteristic,

    defaultCharacteristic,

    firstDefault,
    lastDefault,
    values
  }
}

type returnType = {
  overflowX: "hidden" | "auto"
  isFilter: boolean
  characteristics: characteristicsInterface[]
}

export const getFilterObject = (state: stateType): returnType => {
  const { show, filters, availableCharacteristics } = state
  const characteristicsObject: returnType = {
    overflowX: "hidden",
    isFilter: true,
    characteristics: filters
  }
  const classCharacteristics: returnType = {
    overflowX: "auto",
    isFilter: false,
    characteristics: availableCharacteristics
  }

  return show === "/characteristics"
    ? characteristicsObject
    : classCharacteristics
}

export const getDispatchType = (state: stateType) => {
  const keyword =
    state.modal === "addFilterModal" ? "Filters" : "Characteristics"
  return `setAvailable${keyword}`
}

export const reorderCharacteristics = (
  characteristics: characteristicsInterface[]
) => {
  return [...characteristics].sort((a, b) => {
    const aTheme = a.theme.toUpperCase()
    const bTheme = b.theme.toUpperCase()
    const aMajor = a.major_category.toUpperCase()
    const bMajor = b.major_category.toUpperCase()
    const aName = a.name.toUpperCase()
    const bName = b.name.toUpperCase()
    if (aTheme > bTheme) return 1
    if (aTheme < bTheme) return -1

    if (aMajor > bMajor) return 1
    if (aMajor < bMajor) return -1

    if (aName > bName) return 1
    if (aName < bName) return -1
    return 0
  })
}

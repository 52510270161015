import { initial } from "./constants"
import { stateType } from "./interfaces"

////////////////////////////////////////REDUCER///////////////////////////////

export function reducer(state: stateType, action: any): stateType {
  const { payload, type } = action

  switch (type) {
    case "setShow":
      return {
        ...state,
        show: payload
      }

    case "setAvailableCharacteristics":
      const { availableCharacteristics } = payload
      const filters = state.filters.length
        ? state.filters
        : availableCharacteristics

      const originalFilters = state.originalFilters.length
        ? state.originalFilters
        : availableCharacteristics

      return {
        ...state,
        availableCharacteristics,
        filters,
        originalFilters,
        modal: "",
        modalMessage: ""
      }

    case "setAvailableFilters":
      return {
        ...state,
        filters: payload.availableCharacteristics
      }

    case "setMessage":
      return {
        ...state,
        message: payload,
        loading: false
      }

    case "setLoading":
      return {
        ...state,
        loading: payload
      }

    case "setModal":
      return {
        ...state,
        modal: payload.modal,
        modalMessage: payload.modalMessage
      }

    case "setSchemaData":
      return { ...state, schemaData: payload }

    case "updateSchemaData":
      const nodes = state.schemaData.nodes.map((node) => {
        if (node.id !== payload) return node
        return {
          ...node,
          data: { ...node.data, selected: !node.data.selected }
        }
      })
      return { ...state, schemaData: { ...state.schemaData, nodes } }

    case "setReset":
      return {
        ...state,
        reset: true,
        availableCharacteristics: state.originalFilters,
        filters: state.originalFilters,
        originalFilters: state.originalFilters
      }

    case "clearReset":
      return {
        ...state,
        reset: false
      }

    default:
      throw new Error(`Wrong action type: ${type}`)
  }
}

import { standardProps } from "../common/interfaces"
import { useState, useEffect } from "react"
import {
  categoryType,
  getInitialCharacteristics,
  isSelectedType,
  move,
  reorder
} from "./grabMeFunctions"
import { DropResult, ResponderProvided } from "react-beautiful-dnd"
import { characteristicsInterface } from "../common/types"
import { getDispatchType } from "../common/functions"

export const useCharacteristics = (props: standardProps) => {
  const { state, dispatch } = props

  const [categories, setCategories] = useState<categoryType>(() =>
    getInitialCharacteristics(props)
  )

  useEffect(() => {
    const enabledCharacteristics = categories.selected.map((i) => ({
      ...i,
      enabled: true
    }))
    const disabledCharacteristics = categories.unselected.map((i) => ({
      ...i,
      enabled: false
    }))

    const newAvailableCharacteristics: characteristicsInterface[] = [
      ...enabledCharacteristics,
      ...disabledCharacteristics
    ]

    dispatch({
      type: getDispatchType(state),
      payload: {
        availableCharacteristics: newAvailableCharacteristics
      }
    })
  }, [categories.selected])

  useEffect(() => {
    if (state.reset) {
      setCategories(getInitialCharacteristics(props))
      props.dispatch({
        type: "clearReset"
      })
    }
  }, [state.reset])

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result
    if (!destination) return
    const sourceSelectedType: isSelectedType =
      source.droppableId === "selected" ? "selected" : "unselected"
    const destinationSelectedType: isSelectedType =
      destination.droppableId === "selected" ? "selected" : "unselected"
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "selected") {
        const { droppableId } = source
        const sourceList = categories[sourceSelectedType]
        const items = reorder(sourceList, source.index, destination.index)
        setCategories((oldItems) => ({
          ...oldItems,
          [droppableId]: items
        }))
      }
    } else {
      const { selected, unselected } = move(
        categories[sourceSelectedType],
        categories[destinationSelectedType],
        source,
        destination
      )

      setCategories({ selected, unselected })
    }
  }

  return { categories, setCategories, onDragEnd }
}

import React, { Dispatch, SetStateAction } from "react"
import { categoryType, getItemStyle } from "./grabMeFunctions"
import { Draggable } from "react-beautiful-dnd"
import { Box, Flex, IconButton, Text } from "@chakra-ui/react"
import { IoMdClose } from "react-icons/io"
import { GrFilter } from "react-icons/gr"
import { standardProps } from "../common/interfaces"
import "./polygon.css"
import { InfoIconButton } from "../common/InfoIconButton"
import { reorderCharacteristics } from "../common/functions"

type propTypes = standardProps & {
  name: string
  index: number
  setCategories?: Dispatch<SetStateAction<categoryType>>
}

export default function CustomDraggable(props: propTypes) {
  const { name: targetName, index, setCategories, dispatch, state } = props
  const targetFilter = state.filters.find(({ name }) => name === targetName)!
  const filterColor = targetFilter.enabled ? "green" : "blue"

  const onDeleteClick = () => {
    if (!setCategories) return
    setCategories((oldItems) => {
      const target = oldItems.selected.find(({ name }) => targetName === name)
      if (!target) return oldItems
      const unselectedRaw = [...oldItems.unselected, target]
      const unselected = reorderCharacteristics(unselectedRaw)
      const selected = oldItems.selected.filter(
        ({ name }) => targetName !== name
      )
      return {
        unselected,
        selected
      }
    })
  }

  return (
    <Box fontWeight="bold">
      <Draggable draggableId={targetName} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <Flex justify="space-between">
              <IconButton
                aria-label={"filter"}
                colorScheme={filterColor}
                size="xs"
                icon={<GrFilter size="18px" />}
                onClick={() =>
                  dispatch({
                    type: "setModal",
                    payload: {
                      modal: "addFilterModal",
                      modalMessage: targetName
                    }
                  })
                }
              />

              <Flex justify="center">
                <Text>{targetName}</Text>
                <InfoIconButton {...props} modalMessage={targetName} />
              </Flex>
              <IconButton
                visibility={setCategories ? "visible" : "hidden"}
                aria-label={"delete"}
                colorScheme={"red"}
                size="xs"
                icon={<IoMdClose size="18px" />}
                onClick={onDeleteClick}
              />
            </Flex>
          </div>
        )}
      </Draggable>
    </Box>
  )
}

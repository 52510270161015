import { characteristicsInterface } from "./../common/types"
import { simpleFetch } from "../common/fetchFunctions"
import { dispatchType } from "../common/interfaces"
import { reorderCharacteristics } from "../common/functions"

type readyType = {
  success: boolean
  result: { availableCharacteristics: characteristicsInterface[] }
}

export async function fetchCharacteristics(dispatch: dispatchType) {
  dispatch({
    type: "setLoading",
    payload: true
  })
  const ready: readyType = await simpleFetch(`fetchcharacteristics`)

  if (!ready.success) {
    dispatch({
      type: "setMessage",
      payload: "Failed to connect to the database."
    })
    return
  }

  const { availableCharacteristics } = ready.result

  dispatch({
    type: "setAvailableCharacteristics",
    payload: {
      availableCharacteristics: reorderCharacteristics(availableCharacteristics)
    }
  })

  dispatch({
    type: "setLoading",
    payload: false
  })
}

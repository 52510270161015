import React, { useEffect, FC } from "react"
import { useToast } from "@chakra-ui/react"
import { dispatchType } from "./interfaces"

type propTypes = {
  dispatch: dispatchType
  message: string
}

export const Toaster: FC<propTypes> = (props) => {
  const { message, dispatch } = props
  if (!message) return null
  const errorWords = ["Failed", "Incorrect", "Invalid", "Error"]
  const isPositive = errorWords.every((word) => !message.includes(word))
  const status = isPositive ? "success" : "error"
  let toast = useToast()

  useEffect(() => {
    toast({
      title: "",
      description: message,
      status,
      duration: 9000,
      isClosable: true
    })
    dispatch({
      type: "setMessage",
      payload: ""
    })
  }, [message])

  return null
}

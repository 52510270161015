import { fullResult } from "../Results/TaxonomyDiagram/types"
import { flaskUrl, tooLargeString } from "./constants"

export const simpleFetch = async (path: string) => {
  const result = await fetch(`${flaskUrl}${path}`)
  return await result.json()
}

export const fetchHelper = async (
  path: string,
  body: any
): Promise<any | string> => {
  const result = await fetch(`${flaskUrl}${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then((response) => {
      if (response.ok) return response
      throw new Error("Something went wrong.")
    })
    .then(
      (response: Response) => response.json() // second then()
    )
    .catch((error: Error) => tooLargeString)
  return result
}

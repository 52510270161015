import React, { Dispatch, SetStateAction } from "react"
import { RadioGroup, Flex, Heading, IconButton } from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import { characteristicsInterface } from "../common/types"
import { ShowBinChoices } from "./ShowBinChoices"
import { FiPlus } from "react-icons/fi"
import { getFilterObject } from "../common/functions"

type propsType = standardProps & {
  characteristic: characteristicsInterface
  disabled?: boolean
  setDisabled?: Dispatch<SetStateAction<boolean>>
}

export const ShowDataSources = (props: propsType) => {
  const { characteristic: targetCharacteristic, state, dispatch } = props
  const { column, isNumeric, bins } = targetCharacteristic

  if (!bins.length) return null
  const { isFilter } = getFilterObject(state)

  return (
    <RadioGroup>
      <Flex direction="column" alignItems="flex-start">
        <Flex
          key={`${targetCharacteristic.name}*${column}`}
          w="100%"
          direction="column"
        >
          <ShowBinChoices {...props} />

          {isNumeric && !isFilter && (
            <Flex w="100%" justify="space-between" p="0.5em 0.25em 0 0.25em">
              <IconButton
                visibility="hidden"
                aria-label={"add"}
                size="xs"
                icon={<FiPlus size="18px" />}
              />

              <IconButton
                aria-label={"add"}
                colorScheme={"green"}
                size="xs"
                icon={<FiPlus size="18px" />}
                onClick={() =>
                  dispatch({
                    type: "setModal",
                    payload: {
                      modal: "addNewClass",
                      modalMessage: column
                    }
                  })
                }
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </RadioGroup>
  )
}

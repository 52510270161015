import React, { useEffect } from "react"
import { stateType, dispatchType, standardProps } from "../common/interfaces"
import { Text, Flex, Box, Heading } from "@chakra-ui/react"
import { ShowClasses } from "./ShowClasses"
import { initial } from "../common/constants"

export default function Classes(props: standardProps) {
  useEffect(() => {
    props.dispatch({
      type: "setSchemaData",
      payload: initial.schemaData
    })
  }, [])
  return (
    <Flex
      direction="column"
      h="100%"
      w="100%"
      alignItems="center"
      style={{ gap: "1em" }}
    >
      <Flex h="100%" w="100%" overflowX="auto">
        <ShowClasses {...props} />
      </Flex>
      <Heading h="min-content" size="sm" mb="1em">
        Define classes for each characteristic selected in the previous step.
      </Heading>
    </Flex>
  )
}

export function objectFromArray(array: any, defaultValue: any) {
  return array.reduce((acc: any, value: any) => {
    acc[value] = defaultValue
    return acc
  }, {})
}

export function roundDown(num: number, precision: number) {
  precision = Math.pow(10, precision)
  return Math.floor(num * precision) / precision
}

export function roundUp(num: number, precision: number) {
  precision = Math.pow(10, precision)
  return Math.ceil(num * precision) / precision
}

export const formatNumber = (rawNumber: string | number) => {
  const number = `${rawNumber}`
  return Number(Number.parseFloat(number).toPrecision(5))
}

export const arrayEquality = (short: any[], long: any[]) => {
  if (short.length !== long.length) return false
  return short.every((value, index) => {
    return long[index] === value
  })
}

import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import AddNewClass from "./AddNewClass"
import { closeModal } from "../common/functions"

export const AddNewClassModal = (props: standardProps) => {
  const { state, dispatch } = props

  return (
    <Modal
      isOpen={state.modal === "addNewClass"}
      onClose={() => closeModal(dispatch)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          mb="1em"
          textAlign="center"
          // background="unset"
        >{`Add new class for ${state.modalMessage}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0 2rem 2rem 2rem">
          <AddNewClass {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

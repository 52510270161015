import { workspace, download_url } from "../../common/constants"
import { closeModal } from "../../common/functions"
import { dispatchType } from "../../common/interfaces"

export type formatOption = {
  display: string
  extension: string
  geoserverFormat: string
}

export const downloadFormatOptions: formatOption[] = [
  { display: "Shapefile", extension: "zip", geoserverFormat: "shape-zip" },
  { display: "CSV", extension: "csv", geoserverFormat: "csv" },
  { display: "JSON", extension: "json", geoserverFormat: "application/json" },
  { display: "GML2", extension: "GML", geoserverFormat: "GML2" }
]

export function attachDownload(blob: any, name: string) {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const getBody = (
  tableName: string,
  targetFeatures: number[],
  geoserver: string
) => {
  const ogcFilters = targetFeatures
    .map((id) => `<ogc:FeatureId fid="${tableName}.${id}"/>`)
    .join("")

  return `<wfs:GetFeature service="WFS" version="1.1.0" outputFormat="${geoserver}" 
      xmlns:gsml="urn:cgi:xmlns:CGI:GeoSciML:2.0"
      xmlns:wfs="http://www.opengis.net/wfs"
      xmlns:ogc="http://www.opengis.net/ogc"
      xmlns:gml="http://www.opengis.net/gml">
        <wfs:Query typeName="${workspace}:${tableName}">
          <ogc:Filter>${ogcFilters}</ogc:Filter>
        </wfs:Query>
      </wfs:GetFeature>`
}

export const handleDownload = async (
  targetFeatures: number[],
  downloadFormat: formatOption,
  tableName: string,
  dispatch: dispatchType
) => {
  dispatch({
    type: "setLoading",
    payload: true
  })

  const method = "POST"
  const headers = {
    "Content-Type": "application/xml"
  }
  const body = getBody(
    tableName,
    targetFeatures,
    downloadFormat.geoserverFormat
  )
  const fetched = await fetch(download_url, { method, headers, body })
  const blob = await fetched.blob()
  const name = `NPDamCAT_Selection(${targetFeatures.length}).${downloadFormat.extension}`
  attachDownload(blob, name)
  dispatch({
    type: "setLoading",
    payload: false
  })
  closeModal(dispatch)
}

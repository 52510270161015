import React, { Dispatch, FC, SetStateAction, useState } from "react"
import { getAllIds } from "../common/functions"
import { standardProps } from "../common/interfaces"
import { NoSelection } from "./NoSelection"
import { ShowSpinner } from "./TaxonomyDiagram/ShowSpinner"
import { damDataType, fullDamResult } from "./TaxonomyDiagram/types"
import { GroupCharacteristics } from "./GroupCharacteristics/GroupCharacteristics"
import { ResultsMapContainer } from "./Map/ResultsMapContainer"
import { fetchHelper } from "../common/fetchFunctions"
import { NoResults } from "./TaxonomyDiagram/NoResults"

type propTypes = standardProps & {
  tabIndex: number
  damData: damDataType | null
  setDamData: Dispatch<SetStateAction<damDataType | null>>
  damError: string
  setDamError: Dispatch<SetStateAction<string>>
}

export const RenderVisuals: FC<propTypes> = (props) => {
  const { state, tabIndex, damData, damError, setDamError, setDamData } = props
  const { schemaData } = state

  const selectedFeatures = getAllIds(schemaData)

  if (
    damData === null &&
    !damError &&
    tabIndex > 0 &&
    selectedFeatures.length
  ) {
    fetchHelper("getdamdata", {
      selectedFeatures
    }).then((a: fullDamResult | string) => {
      if (typeof a === "string") return setDamError(a)
      setDamData(a.result.list)
    })
  }

  if (damError) return <NoResults message={damError} />
  if (selectedFeatures.length === 0) return <NoSelection />
  if (!damData) return <ShowSpinner />

  if (tabIndex === 1)
    return <ResultsMapContainer {...props} damData={damData} />

  return <GroupCharacteristics {...props} damData={damData} />
}

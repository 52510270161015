import React from "react"
import { getListStyle } from "./grabMeFunctions"
import { Droppable } from "react-beautiful-dnd"
import { characteristicsInterface } from "../common/types"
import CustomDraggable from "./CustomDraggable"
import { standardProps } from "../common/interfaces"

type propTypes = standardProps & {
  list: characteristicsInterface[]
  droppableId: string
  majorCategory: string
}

export default function CustomDraggableList(props: propTypes) {
  const { list, droppableId, majorCategory } = props

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver, false)}
        >
          {list.map(({ name, major_category: thisMajorCategory }, index) => {
            if (majorCategory !== thisMajorCategory) return null
            return (
              <CustomDraggable
                key={name}
                name={name}
                index={index}
                {...props}
              />
            )
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

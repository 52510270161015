import React, { Dispatch } from "react"
import { standardProps } from "../common/interfaces"
import { Button, Flex, IconButton } from "@chakra-ui/react"
import { binType, characteristicsInterface } from "../common/types"
import { FiPlus, FiMinus } from "react-icons/fi"
import { setAllBins } from "./functions"
import { getDispatchType, getFilterObject } from "../common/functions"

type propsType = standardProps & {
  characteristic: characteristicsInterface
  array: string[]
  setArray: Dispatch<React.SetStateAction<string[]>>
  values: number[]
}

export default function AddNewClassButtons(props: propsType) {
  const {
    state,
    characteristic: targetCharacteristic,
    dispatch,
    array,
    setArray,
    values
  } = props

  const { availableCharacteristics, modalMessage } = state

  const last = values[values.length - 1]

  const set = new Set(array)
  const hasDuplicate = set.size !== array.length
  const isOrdered = array.every((numberString, index, array) => {
    if (index === 0) return true
    return +numberString > +array[index - 1]
  })
  const isAllNumbers = array.every((numberString) => {
    return !isNaN(+numberString)
  })

  const onClick = () => {
    const values = array.map((i) => ({ value: +i, enabled: true }))
    const newUserBin: binType = {
      userDefined: true,
      enabled: true,
      values,
      valueNames: []
    }
    const newCharacteristics: characteristicsInterface[] =
      availableCharacteristics.map((characteristic) => {
        if (targetCharacteristic.name !== characteristic.name)
          return characteristic

        if (modalMessage !== characteristic.column) {
          return {
            ...characteristic,
            bins: setAllBins(characteristic.bins, false)
          }
        }

        const rawBins = characteristic.bins.map((bin) => {
          return bin.userDefined ? newUserBin : { ...bin, enabled: false }
        })
        const userDefinedBinsExist = characteristic.bins.find(
          ({ userDefined }) => userDefined
        )
        const bins = userDefinedBinsExist ? rawBins : [...rawBins, newUserBin]

        return { ...characteristic, bins }
      })
    dispatch({
      type: getDispatchType(state),
      payload: { availableCharacteristics: newCharacteristics }
    })
  }

  return (
    <>
      <Flex>
        <IconButton
          mr="2em"
          aria-label={"delete"}
          colorScheme={"red"}
          size="xs"
          icon={<FiMinus size="18px" />}
          isDisabled={array.length <= 2}
          onClick={() => {
            setArray((oldArray) => oldArray.slice(0, -1))
          }}
        />
        <IconButton
          aria-label={"add"}
          colorScheme={"green"}
          size="xs"
          icon={<FiPlus size="18px" />}
          onClick={() => {
            setArray((oldArray) => [...oldArray, last.toString()])
          }}
        />
      </Flex>

      <Button
        pointerEvents="all"
        w="8em"
        m="0.625em 0"
        colorScheme={"green"}
        justifySelf="flex-end"
        isDisabled={hasDuplicate || !isOrdered || !isAllNumbers}
        onClick={onClick}
      >
        Add Class
      </Button>
    </>
  )
}

export const defaultZoom = 4

export const defaultPosition = [36.6332, -95.2982]

export const basemapsRaw = [
  {
    url: "//{s}.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
    metadata: {
      attribution:
        "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS",
      subdomains: ["server", "services"],
      label: "ESRI Topo"
    }
  },
  {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    metadata: {
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, TomTom, 2012",
      label: "OSM"
    }
  },
  {
    url: "//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    metadata: {
      attribution:
        "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS",
      label: "ESRI Imagery"
    }
  },
  {
    url: "//{s}.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
    metadata: {
      attribution: "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
      maxZoom: 16,
      subdomains: ["server", "services"],
      label: "ESRI Gray"
    }
  }
]

export const layerName = "npd_data_v4"

export const getPointStyle = {
  [layerName]: function () {
    return {
      opacity: 0,
      fillOpacity: 0,
      fill: true,
      fillColor: "blue",
      color: "black",
      radius: 0.0000001,
      weight: 0.0000001
    }
  }
}

export const huc8_bounds = {
  south: 24.251479003842288,
  west: -125.13879555799888,
  north: 49.38435799906803,
  east: -66.59562442454103
}

export const makeVisible = {
  fillOpacity: 0.9,
  opacity: 1,
  radius: 4,
  weight: 2
}

export const initialStyle = {
  color: "black",
  fillColor: "red",
  weight: 2,
  fillOpacity: 0.8,
  radius: 5
}

export const highlightColor = "yellow"

import React from "react"

import { FC } from "react"
import { Link } from "@chakra-ui/react"
import { linkColor } from "../common/constants"

type propTypes = {
  href: string
}

export const CustomLink: FC<propTypes> = (props) => {
  const { href, children } = props

  return (
    <Link href={href} isExternal color={linkColor} fontWeight={"500"}>
      {children}
    </Link>
  )
}

import React, { FC } from "react"
import { Box } from "@chakra-ui/react"
import { Stat } from "./Stat"
import { formatNumber } from "../../common/utilities"

type propTypes = {
  value: number
  units: string
  statType: string
}

export const ShowStat: FC<propTypes> = (props) => {
  const { value, units, statType } = props
  const isNotNumber = isNaN(+value)

  const statText = isNaN(value) ? "" : `${formatNumber(value)}`
  const statVisibility = !isNotNumber ? "visible" : "hidden"

  return (
    <Box visibility={statVisibility} h="80px">
      <Stat units={units} text={statText} statType={statType} />
    </Box>
  )
}

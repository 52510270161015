import React from "react"
import { Flex, Box } from "@chakra-ui/react"

export function HeaderFormatter(
  column: any,
  columnIndex: number,
  components: any
) {
  return (
    <Flex justify="center">
      {column.text}
      <Box m="auto 0 0 5px" color="grey">
        {components.sortElement}
      </Box>
    </Flex>
  )
}

import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from "@chakra-ui/react"
import { standardProps } from "../../common/interfaces"
import { Download } from "./Download"
import { closeModal, getAllIds } from "../../common/functions"

export const DownloadModal = (props: standardProps) => {
  const { state, dispatch } = props
  const selectedFeatures = getAllIds(state.schemaData)

  return (
    <Modal
      isOpen={state.modal === "download"}
      onClose={() => closeModal(dispatch)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          mb="1em"
          textAlign="center"
          background="unset"
        >{`Download Features (${selectedFeatures.length})`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0 2rem 2rem 2rem">
          <Download {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import React from "react"
import { Button, Flex, Spinner } from "@chakra-ui/react"
import { SpinnerBox } from "../Results/SpinnerBox"
import ContinueButton from "./ContinueButton"
import { standardProps } from "./interfaces"
import { getAllIds } from "./functions"

export const BottomBar = (props: standardProps) => {
  const selectedFeatures = getAllIds(props.state.schemaData)
  return (
    <Flex h="5em" p="1em" w="100%" justify="space-between">
      <ContinueButton direction="Back" {...props} />
      <SpinnerBox>
        {props.state.loading && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
          />
        )}
      </SpinnerBox>
      {props.state.show === "/results" ? (
        <Button
          colorScheme="green"
          w="12em"
          mt="0.625em"
          isDisabled={props.state.loading || !selectedFeatures.length}
          onClick={() => {
            props.dispatch({
              type: "setModal",
              payload: {modal:"download",modalMessage:""}
            })
          }}
        >
          {`Download (${selectedFeatures.length})`}
        </Button>
      ) : (
        <ContinueButton direction="Continue" {...props} />
      )}
    </Flex>
  )
}

import React from "react"
import { Flex, Button } from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import { closeModal } from "../common/functions"
import { characteristicsInterface } from "../common/types"

type propsType = standardProps & {
  filterCharacteristic: characteristicsInterface
  disabled: boolean
}

export const AddFilterButtons = (props: propsType) => {
  const { state, dispatch, filterCharacteristic, disabled } = props
  const { originalFilters, filters, modalMessage } = state

  const original = originalFilters.find(
    ({ name }) => name === filterCharacteristic.name
  )!

  const bin = filterCharacteristic.bins.find(
    ({ enabled, userDefined }) => enabled && userDefined
  )

  const originalBin = original.bins[0].values

  const [min, max] = [
    originalBin[0].value,
    originalBin[originalBin.length - 1].value
  ]

  const onClickReset = () => {
    closeModal(dispatch)
    const availableCharacteristics = filters.map((filter) =>
      filter.name === filterCharacteristic.name ? original : filter
    )
    dispatch({
      type: "setAvailableFilters",
      payload: { availableCharacteristics }
    })
  }

  const onClickApply = () => {
    closeModal(dispatch)
    const availableCharacteristics = filters.map((filter) =>
      filter.name === filterCharacteristic.name
        ? { ...filter, enabled: true }
        : filter
    )
    dispatch({
      type: "setAvailableFilters",
      payload: { availableCharacteristics }
    })
  }

  return (
    <Flex w="100%" justify="space-between">
      <Button colorScheme="red" w="8em" m="4px" onClick={onClickReset}>
        Reset Filter
      </Button>
      <Button
        m="4px"
        disabled={disabled}
        colorScheme="green"
        w="8em"
        onClick={onClickApply}
      >
        OK
      </Button>
    </Flex>
  )
}

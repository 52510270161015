import { getURL } from "./functions"
import { Dispatch, MutableRefObject, SetStateAction } from "react"
import L, { CircleMarker } from "leaflet"
import "leaflet.vectorgrid"
import "leaflet-basemaps"
import "./basemaps.css"
import { standardProps } from "../../common/interfaces"
import {
  basemapsRaw,
  defaultPosition,
  defaultZoom,
  highlightColor,
  initialStyle
} from "./constants"
import { damDataType } from "../TaxonomyDiagram/types"

type propTypes = standardProps & {
  setSelectedPoints: Dispatch<SetStateAction<number[]>>
  damData: damDataType
  leafletLayersRef: MutableRefObject<L.CircleMarker[]>
}

export const createMap = (props: propTypes) => {
  const { setSelectedPoints, damData, leafletLayersRef } = props
  const newMap = L.map("mapArea", {
    center: L.latLng(defaultPosition[0], defaultPosition[1]),
    zoom: defaultZoom
  })

  //add Basemaps
  newMap.addControl(
    // @ts-ignore
    L.control.basemaps({
      basemaps: basemapsRaw.map(({ url, metadata }) =>
        L.tileLayer(url, metadata)
      ),
      position: "bottomleft",
      tileX: 0,
      tileY: 0,
      tileZ: 1
    })
  )

  // const options = {
  //   subdomains: "",
  //   maxNativeZoom: 14,
  //   vectorTileLayerStyles: getPointStyle,
  //   interactive: true,
  //   getFeatureId: (f: any) => f.properties.id,
  //   // @ts-ignore
  //   rendererFactory: L.canvas.tile,
  //   bounds: L.latLngBounds(
  //     L.latLng(huc8_bounds.south, huc8_bounds.west),
  //     L.latLng(huc8_bounds.north, huc8_bounds.east)
  //   )
  // }
  // @ts-ignore
  // const newLayer = L.vectorGrid
  //   .protobuf(getURL(layerName), options)
  //   .on("click", (e: any) => {
  //     const target = e.layer.properties.id
  //     setSelectedPoints((oldPoints) => {
  //       return oldPoints.includes(target)
  //         ? oldPoints.filter((id) => id !== target)
  //         : [...oldPoints, target]
  //     })
  //   })

  // newLayer.on("loading", function (event: any) {
  //   dispatch({
  //     type: "setLoading",
  //     payload: true
  //   })
  // })

  // newLayer.on("load", function (event: any) {
  //   dispatch({
  //     type: "setLoading",
  //     payload: false
  //   })
  // })

  // newLayer.addTo(newMap)
  // setMapLayer(newLayer)

  const circleArray: L.CircleMarker[] = damData.map(
    ({ latitude, longitude, id }) => {
      const layer = L.circleMarker([latitude, longitude], initialStyle).on(
        "click",
        (e) => {
          const isInitial = e.target.options.color === initialStyle.color
          const color = isInitial ? highlightColor : initialStyle.color
          e.target.setStyle({ ...initialStyle, color })
          setSelectedPoints((oldPoints) => {
            return oldPoints.includes(+id)
              ? oldPoints.filter((old_id) => old_id !== +id)
              : [...oldPoints, +id]
          })
        }
      )

      leafletLayersRef.current.push(layer)

      return layer
    }
  )
  const layer = L.featureGroup(circleArray)

  layer.addTo(newMap)

  newMap.doubleClickZoom.disable()
}

import React from "react"
import { characteristicsInterface } from "../common/types"
import { standardProps } from "../common/interfaces"
import CustomCard from "../common/CustomCard"
import CustomDraggableList from "./CustomDraggableList"
import { colorPalette } from "../common/constants"

type propTypes = standardProps & {
  list: characteristicsInterface[]
  droppableId: string
  theme: string
}

export default function ThemeList(props: propTypes) {
  const { list, theme } = props

  const targetMajorCategories = list.filter(
    ({ theme: thisTheme }) => theme === thisTheme
  )
  const majorCategoriesArray = targetMajorCategories.map(
    ({ major_category }) => major_category
  )

  const majorCategories = [...new Set(majorCategoriesArray)].sort()

  return (
    <CustomCard
      key={theme}
      title={theme}
      style={{ marginBottom: "1em" }}
      iconType="expand"
      hideBody={true}
      innerStyle={{
        height: "100%",
        paddingTop: "0em",
        paddingBottom: "0em"
      }}
    >
      {majorCategories.map((majorCategory) => {
        if (majorCategories.length === 1) {
          return (
            <CustomDraggableList
              key={majorCategory}
              majorCategory={majorCategory}
              {...props}
            />
          )
        }

        return (
          <CustomCard
            key={majorCategory}
            title={majorCategory}
            style={{ marginBottom: "1em", width: "85%", margin: "0.5em auto" }}
            headerColor={colorPalette[5]}
            iconType="expand"
            hideBody={true}
            innerStyle={{
              height: "100%",
              paddingTop: "0em",
              paddingBottom: "0em"
            }}
          >
            <CustomDraggableList
              key={majorCategory}
              majorCategory={majorCategory}
              {...props}
            />
          </CustomCard>
        )
      })}
    </CustomCard>
  )
}

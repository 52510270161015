import { Box } from "@chakra-ui/react"
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect
} from "react"
import { standardProps } from "../../common/interfaces"
import { damDataType } from "../TaxonomyDiagram/types"
import { createMap } from "./createMap"

type propsType = standardProps & {
  setSelectedPoints: Dispatch<SetStateAction<number[]>>
  selectedPoints: number[]
  leafletLayersRef: MutableRefObject<L.CircleMarker[]>
  damData: damDataType
  gridArea: string
}

export const DataMap = (props: propsType) => {
  // create map
  useEffect(() => {
    createMap(props)
  }, [])

  return (
    <Box
      id={"mapArea"}
      gridArea={props.gridArea}
      cursor="auto"
      border="0.0625em solid black"
    />
  )
}

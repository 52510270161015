import React, { Dispatch, SetStateAction } from "react"
import { Flex, Radio } from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import { characteristicsInterface, binType } from "../common/types"
import { BinArray } from "./BinArray"

import { getDispatchType, getFilterObject } from "../common/functions"
import { setAllBins } from "./functions"
import { arrayEquality } from "../common/utilities"

type propsType = standardProps & {
  characteristic: characteristicsInterface
  disabled?: boolean
  setDisabled?: Dispatch<SetStateAction<boolean>>
}

export const ShowBinChoices = (props: propsType) => {
  const { state, characteristic, dispatch } = props
  const { bins, isNumeric } = characteristic
  const userBin = bins.findIndex(({ userDefined }) => userDefined)

  const { characteristics } = getFilterObject(state)

  const onChange = (bin: binType) => {
    const newCharacteristics: characteristicsInterface[] = characteristics.map(
      (availableCharacteristic) => {
        if (availableCharacteristic.name !== characteristic.name)
          return availableCharacteristic

        if (characteristic.column !== availableCharacteristic.column)
          return {
            ...availableCharacteristic,
            bins: setAllBins(availableCharacteristic.bins, false)
          }

        const bins = availableCharacteristic.bins.map((oldBins) => {
          const allValues1 = oldBins.values.map(({ value }) => value)
          const allValues2 = bin.values.map(({ value }) => value)
          const enabled = arrayEquality(allValues1, allValues2)

          return { ...oldBins, enabled }
        })

        return { ...availableCharacteristic, bins }
      }
    )
    dispatch({
      type: getDispatchType(state),
      payload: { availableCharacteristics: newCharacteristics }
    })
    window.scrollTo(0, 0)
  }

  return (
    <Flex direction="column">
      {bins.map((bin, index) => {
        return (
          <Flex key={`numeric_filter_${index}`} justify="center">
            {state.modal !== "addFilterModal" && userBin !== -1 && (
              <Radio
                onChange={() => onChange(bin)}
                bg="lightgray"
                isChecked={bin.enabled}
              />
            )}
            <BinArray bin={bin} {...props} />
          </Flex>
        )
      })}
    </Flex>
  )
}

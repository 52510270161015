import React, { FC } from "react"

import { Flex, Heading } from "@chakra-ui/react"
import { capitalizeFirstLetter } from "../common/functions"

type propTypes = {
  droppableId: string
}

export const DroppableContainer: FC<propTypes> = (props) => {
  const { droppableId, children } = props
  const minWidth = droppableId === "unselected" ? "25em" : "10em"

  return (
    <Flex
      m="0 1.25em"
      direction="column"
      alignItems="center"
      w="100%"
      minW={minWidth}
      // minW={minWidth}

      h="100%"
    >
      <Heading mb="0.3125em" size="md">
        {`${capitalizeFirstLetter(droppableId)}`}
      </Heading>
      {children}
    </Flex>
  )
}

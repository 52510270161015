import React, { FC, memo } from "react"
import { Bar } from "react-chartjs-2"

type propTypes = {
  data: any
  options: any
  selectedAttribute: string
}

const WrappedComponent: FC<propTypes> = ({ data, options }) => {
  return <Bar type={"bar"} data={data} options={options} />
}

export const BarChart = memo(WrappedComponent, (a, b) => {
  return a.selectedAttribute === b.selectedAttribute
})

import React, { FC } from "react"
import CustomCard from "../../common/CustomCard"
import { getAllIds } from "../../common/functions"
import { standardProps } from "../../common/interfaces"
import { NoSelection } from "../NoSelection"
import { RenderBarChart } from "./RenderBarChart"
import { useBarChart } from "./useBarChart"

import { Flex, Text } from "@chakra-ui/react"
import { damDataType } from "../TaxonomyDiagram/types"

type propTypes = standardProps & {
  damData: damDataType
}

export const GroupCharacteristics: FC<propTypes> = (props) => {
  const { state } = props
  const { schemaData } = state

  const selectedFeatures = getAllIds(schemaData)

  if (selectedFeatures.length === 0) return <NoSelection />

  return (
    <Flex direction="column" h="100%" overflowY="auto">
      <CustomCard
        title={"Group Characteristics"}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden"
        }}
        innerStyle={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          justifyContent: "space-between",
          padding: "0.25rem"
        }}
      >
        <RenderBarChart {...props} />
      </CustomCard>
      <Flex h="min-content" justify="center">
        <Text textAlign="center" w="50%">
          Select an attribute from the drop-down menu to explore variability
          within selected groups of dams.
        </Text>
      </Flex>
    </Flex>
  )
}

import React from "react"
import { stateType, dispatchType, standardProps } from "../common/interfaces"
import { IconButton } from "@chakra-ui/react"
import { BsInfoCircleFill } from "react-icons/bs"

type propTypes = {
  dispatch: dispatchType
  modalMessage: string
  colorScheme?: string
}

export const InfoIconButton = (props: propTypes) => {
  const { dispatch, modalMessage, colorScheme = "blue" } = props

  return (
    <IconButton
      aria-label="info"
      colorScheme={colorScheme}
      icon={<BsInfoCircleFill size={"15px"} />}
      isRound={true}
      size="xs"
      mt="0.1em"
      variant="ghost"
      onClick={() =>
        dispatch({
          type: "setModal",
          payload: {
            modal: "infoModal",
            modalMessage
          }
        })
      }
    />
  )
}

import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  Heading,
  Box
} from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import { closeModal } from "../common/functions"

export const InfoModal = (props: standardProps) => {
  const { state, dispatch } = props
  const { availableCharacteristics, modalMessage } = state

  const raw = availableCharacteristics.find(({ name }) => name === modalMessage)
  const characteristic = raw || availableCharacteristics[0]
  const { units, column, description, data_source_description } = characteristic

  return (
    <Modal
      isOpen={state.modal === "infoModal"}
      onClose={() => closeModal(dispatch)}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">{raw?.name || "Info"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex maxH="70vh" overflowY="auto" direction="column" w="100%">
            <Flex direction="column" alignItems="flex-start" ml="1em">
              {!raw ? (
                <Text>{modalMessage}</Text>
              ) : (
                <Flex
                  key={`${column}`}
                  w="100%"
                  direction="column"
                  pl="1em"
                  pb="1em"
                  mb="1em"
                  gridGap="2em"
                >
                  {!!data_source_description.trim() && (
                    <Box>
                      <Heading as="h6" size="sm">
                        Source
                      </Heading>
                      <Text> {data_source_description}</Text>
                    </Box>
                  )}
                  {!!units.trim() && units !== "unitless" && (
                    <Box>
                      <Heading as="h6" size="sm">
                        Units
                      </Heading>
                      <Text> {units}</Text>
                    </Box>
                  )}

                  {!!description.trim() && (
                    <Box>
                      <Heading as="h6" size="sm">
                        Description
                      </Heading>
                      <Text> {description}</Text>
                    </Box>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

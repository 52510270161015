import React from "react"
import { Text, Flex, Heading, Box } from "@chakra-ui/react"
import { CustomLink } from "./CustomLink"
import { StaticImage } from "gatsby-plugin-image"

export default function LandingPage() {
  return (
    <Flex
      h="100%"
      maxW="60em"
      justify="center"
      direction="column"
      margin="auto"
    >
      <Flex
        h="100%"
        maxH="30em"
        justify="space-around"
        alignItems="center"
        direction="column"
        overflow="auto"
      >
        <Heading as="h4" size="md">
          NPDamCAT - Non-Powered Dam Custom Analysis and Taxonomy
        </Heading>

        <Text>
          The Non-Powered Dam Custom Analysis and Taxonomy (NPDamCAT) tool was
          developed by the{" "}
          <CustomLink href="https://www.ornl.gov/waterpower">
            Water Power Program
          </CustomLink>
          {" at "}
          <CustomLink href="https://www.ornl.gov/">
            Oak Ridge National Laboratory (ORNL)
          </CustomLink>
          . Funding is provided by the U.S. Department of Energy’s{" "}
          <CustomLink href="https://www.energy.gov/eere/water/water-power-technologies-office">
            Water Power Technologies Office
          </CustomLink>
          . The NPDamCAT tool allows you to classify dams into groups and then
          compare characteristics within or across different groups.
        </Text>
        <Box>
          <StaticImage src="../../images/workflow.png" alt="workflow" />
        </Box>
      </Flex>
    </Flex>
  )
}

// The Non-Powered Dam Custom Analysis and Taxonomy (NPDamCAT) was developed by the Water Power Program at Oak Ridge National Laboratory (ORNL). Funding is provided by the U.S. Department of Energy’s Water Power Technologies Office. The NPDamCAT tool facilitates the grouping and classification of Non-Powered Dams based on user-defined criteria.

import React from "react"
import { standardProps } from "../common/interfaces"
import { Flex, Heading, Box, IconButton, Button } from "@chakra-ui/react"
import DragCharacteristics from "./DragCharacteristics"
import { BsInfoCircleFill } from "react-icons/bs"

export default function Characteristics(props: standardProps) {
  const modalMessage = `Ordering groups/sub-groups: Suppose you want to create groups based primarily 
  on dam purpose, but then you want to explore sub-groups based on architectural type. You would need to make sure the first 
  item in the “Selected” side is “Purpose”, 
  and the second is “Architectural/Structural type”.
  
  Filtering dams: For classifying a specific subset of dams, click on the filter icon to specify the values you wish to include. For example, if you want to only classify dams in the eastern US (e.g., Hydrologic Regions 01-03), click on the filter icon next to “Hydrologic Region” and only select “1”, “2”, and “3” in the values, and enable the filter.
  `

  const heading = `Select which characteristics you would like to use to build your NPD taxonomy 
  by dragging and dropping the characteristics to the right-hand side to include them. 
  Then, arrange the selected characteristics in the order you would like groups to be created.
   Characteristics can also be filtered by clicking on the icon on 
   the left-hand side of each characteristic name. The filter may be applied on
    unselected characteristics to limit the initial NPD population upon which the NPD taxonomy is 
    built, or it may be applied on selected characteristics to organize the taxonomy.`

  return (
    <Flex h="100%" justify="center" w="100%">
      <Flex h="100%" maxW="80em" direction="column" gridGap="1em">
        <DragCharacteristics {...props} />
        <Button
          alignSelf="center"
          w="5em"
          colorScheme="red"
          size="sm"
          onClick={() => {
            props.dispatch({
              type: "setReset"
            })
          }}
        >
          Reset
        </Button>
        <Heading alignSelf="center" textAlign="center" size="sm">
          {heading}
        </Heading>
        <Flex w="100%" justify="center">
          <Heading alignSelf="center" size="xs">
            Example:
          </Heading>
          <IconButton
            aria-label="info"
            colorScheme={"blue"}
            icon={<BsInfoCircleFill size={"20px"} />}
            isRound={true}
            size="sm"
            variant="ghost"
            onClick={() =>
              props.dispatch({
                type: "setModal",
                payload: {
                  modal: "characteristicsInfoModal",
                  modalMessage
                }
              })
            }
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

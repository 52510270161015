import React, { FC } from "react"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "./table.css"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"
import { Flex, Box } from "@chakra-ui/react"
import { stateType, dispatchType } from "../common/interfaces"
import { getColumns } from "./functions"
import { TableCard } from "./TableCard"
import { damDataType } from "../Results/TaxonomyDiagram/types"

type propTypes = {
  state: stateType
  dispatch: dispatchType
  selectedDams: damDataType | undefined
}

export const Table: FC<propTypes> = (props) => {
  const { selectedDams } = props

  return (
    <TableCard>
      <Box id="scrollTable" w="100%" overflow="auto">
        {!!selectedDams && selectedDams.length ? (
          <BootstrapTable
            keyField="id"
            headerClasses="metadataHeaders"
            bordered={true}
            striped={true}
            data={selectedDams}
            columns={getColumns({ selectedDams })}
            filter={filterFactory()}
            // selectRow={getSelectRow(props)}
          />
        ) : (
          "Click Dams to Show Table"
        )}
      </Box>
    </TableCard>
  )
}

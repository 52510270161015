import React, { FC } from "react"
import { AddFilterModal } from "../Characteristics/AddFilterModal"
import { CharacteristicsInfoModal } from "../Characteristics/CharacteristicsInfoModal"
import { InfoModal } from "../Characteristics/InfoModal"
import { AddNewClassModal } from "../Classes/AddNewClassModal"
import { DownloadModal } from "../Results/Download/DownloadModal"
import { standardProps } from "./interfaces"

type allModalType = { [i: string]: any }

export const ModalHandler = (props: standardProps) => {
  const allModals: allModalType = {
    download: <DownloadModal {...props} />,
    addNewClass: <AddNewClassModal {...props} />,
    addFilterModal: <AddFilterModal {...props} />,
    infoModal: <InfoModal {...props} />,
    characteristicsInfoModal: <CharacteristicsInfoModal {...props} />
  }
  const modal = allModals[props.state.modal]
  return <>{modal || null}</>
}

import React, { FC } from "react"
import { Flex, Box } from "@chakra-ui/react"
import { Brand } from "./Brand"
import styled from "@emotion/styled"
import { colorPalette, headerHeight } from "./common/constants"
import { StaticImage } from "gatsby-plugin-image"

const HeaderDiv = styled.header(() => ({
  background: colorPalette[2],
  marginBottom: 0,
  height: headerHeight,
  width: "100%"
}))

export const Header: FC = (props) => {
  return (
    <HeaderDiv>
      <Flex justify="space-between" alignItems="center" h="100%" p="0 1.0875em">
        <Flex direction="column" justify="center" width="13em">
          <StaticImage src="../images/ORNL_Two-line_white.png" alt="ORNL" />
        </Flex>
        <Brand {...props} />
        <Box width="13em" />
      </Flex>
    </HeaderDiv>
  )
}

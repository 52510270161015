import React, { FC } from "react"
import { Flex, Text } from "@chakra-ui/react"
import CustomCard from "../../common/CustomCard"

type propTypes = {
  text: string
  units: string
  statType: string
}

export const Stat: FC<propTypes> = (props) => {
  const { text, units, statType } = props
  return (
    <CustomCard
      style={{ height: "100%", background: "white" }}
      innerStyle={{ height: "100%", padding: "5px" }}
      title={statType}
    >
      <Flex h="100%" direction="column" justify="space-around">
        <Text>{text}</Text>
        <Text>{`(${units})`}</Text>
      </Flex>
    </CustomCard>
  )
}

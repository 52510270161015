import React, { useRef, useState } from "react"
import { standardProps } from "../../common/interfaces"
import { DataMap } from "./DataMap"
import { getAllIds } from "../../common/functions"
import { NoSelection } from "../NoSelection"
import { Table } from "../../Table/Table"
import { Button, Flex, Grid } from "@chakra-ui/react"
import { damDataType } from "../TaxonomyDiagram/types"
import { useEffect } from "react"
import { highlightColor, initialStyle } from "./constants"
import { MapButtons } from "./MapButtons"

type propTypes = standardProps & {
  damData: damDataType
}

export const ResultsMapContainer = (props: propTypes) => {
  const { state, damData } = props
  const { schemaData } = state

  const [selectedPoints, setSelectedPoints] = useState<number[]>([])
  const leafletLayersRef = useRef<L.CircleMarker[]>([])

  const selectedDams = damData.filter(({ id }) => selectedPoints.includes(+id))

  const selectedFeatures = getAllIds(schemaData)

  if (selectedFeatures.length === 0) return <NoSelection />

  return (
    <Grid
      gridTemplateRows="1fr 20px"
      gridTemplateColumns="95px 1fr"
      h="100%"
      w="100%"
    >
      <DataMap
        setSelectedPoints={setSelectedPoints}
        leafletLayersRef={leafletLayersRef}
        gridArea="1 / 1 / -1 / -1"
        selectedPoints={selectedPoints}
        {...props}
      />

      <Flex
        w="100%"
        alignItems="flex-end"
        gridArea="1 / 2 / -2 / 3"
        pointerEvents="none"
        zIndex="998"
        minW="0"
        minH="0"
      >
        <Table {...props} selectedDams={selectedDams} />
        <MapButtons
          setSelectedPoints={setSelectedPoints}
          leafletLayersRef={leafletLayersRef}
          {...props}
        />
      </Flex>
    </Grid>
  )
}

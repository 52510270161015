import React, { Dispatch, Fragment, SetStateAction } from "react"
import { getListStyle, categoryType } from "./grabMeFunctions"
import { Droppable } from "react-beautiful-dnd"
import { characteristicsInterface } from "../common/types"
import CustomDraggable from "./CustomDraggable"
import { DroppableContainer } from "./DroppableContainer"
import { standardProps } from "../common/interfaces"

type propTypes = standardProps & {
  list: characteristicsInterface[]
  setCategories: Dispatch<SetStateAction<categoryType>>
}

export default function SelectedDroppableInstance(props: propTypes) {
  const { list } = props
  const droppableId = "selected"
  return (
    <DroppableContainer droppableId={droppableId}>
      <Fragment key={droppableId}>
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver, true)}
            >
              {list.map(({ name }, index) => {
                return (
                  <CustomDraggable
                    key={name}
                    name={name}
                    index={index}
                    {...props}
                  />
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Fragment>
    </DroppableContainer>
  )
}

import { nullValue } from "./../../common/constants"
import { colorPalette } from "../../common/constants"
import { damDataType } from "../TaxonomyDiagram/types"
import { attributeType } from "./../../common/interfaces"
import { nullValues } from "./constants"

export function compareFunction(a: [string, number], b: [string, number]) {
  if (a[1] === b[1]) {
    if (a[0] > b[0]) return 1
    if (a[0] < b[0]) return -1
    return 0
  }
  return a[1] - b[1]
}

type sortArrayProps = {
  damData: damDataType
  selectedAttribute: string
}

export function sortArray({ selectedAttribute, damData }: sortArrayProps) {
  const filtered = damData.filter(
    (e) => !nullValues.includes(e[selectedAttribute])
  )

  const unsortedArray = filtered.map((e) => e[selectedAttribute])
  const sortedArray = unsortedArray.sort((a, b) => a - b)
  const labels = filtered.map(({ nidid }) => nidid)
  return { sortedArray, labels }
}

export function getCategoryData({
  selectedAttribute,
  damData
}: sortArrayProps) {
  const valueMap = new Map()
  damData.forEach((row) => {
    const category = row[selectedAttribute] || "NA"
    const currentValue = valueMap.get(category)
    const count = currentValue ? currentValue + 1 : 1
    valueMap.set(category, count)
  })
  const sortedMap = new Map([...valueMap.entries()].sort(compareFunction))
  const sortedArray = Array.from(sortedMap.values())
  const rawLabels = Array.from(sortedMap.keys())
  const labels = rawLabels.map((a) => (a === nullValue ? "No Data" : a))

  return { sortedArray, labels }
}

export const getOptions = (attribute: attributeType) => {
  const { units, humanName, isNumeric } = attribute
  return {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: isNumeric ? "NIDID" : humanName
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: isNumeric ? units : "Count"
        },
        ticks: {
          callback: (val: number) =>
            val === parseInt(val.toString(), 10) ? val : ""
        },
        grid: {
          color: (val: any) => (val.tick.label ? "lightgrey" : colorPalette[0])
        }
      }
    },

    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 0
        }
      }
    }
  }
}

import React, { ChangeEvent, Dispatch, SetStateAction } from "react"
import { Flex, Text, Box, Radio, Button } from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import { binType, characteristicsInterface } from "../common/types"
import { nullValues } from "../Results/GroupCharacteristics/constants"
import { arrayEquality } from "../common/utilities"
import { setAllBins } from "./functions"
import {
  getDispatchType,
  getFilterObject,
  sortObjects
} from "../common/functions"
import { AddClassFilter } from "./AddClassFilter"

type propsType = standardProps & {
  bin: binType

  characteristic: characteristicsInterface
}

type onChangeProps = {
  e?: ChangeEvent<HTMLInputElement>
  clearAll?: boolean
  selectAll?: boolean
  disabled?: boolean
  setDisabled?: Dispatch<SetStateAction<boolean>>
}

export const BinArray = (props: propsType) => {
  const { state, characteristic: targetCharacteristic, dispatch, bin } = props

  const isAllNumbers = bin.values.every(({ value }) => !isNaN(+value))

  const sortedValues = isAllNumbers
    ? bin.values.sort(sortObjects("value", "numbers"))
    : bin.values

  const { isNumeric, column } = targetCharacteristic

  const { characteristics, isFilter } = getFilterObject(state)

  const type = getDispatchType(state)

  const onChange = (props: onChangeProps) => {
    const { e, clearAll, selectAll } = props
    const newCharacteristics: characteristicsInterface[] = characteristics.map(
      (characteristic) => {
        if (targetCharacteristic.name !== characteristic.name)
          return characteristic

        if (column !== characteristic.column)
          return {
            ...characteristic,
            bins: setAllBins(characteristic.bins, false)
          }
        const bins = characteristic.bins.map((thisBin) => {
          const allValues1 = thisBin.values.map(({ value }) => value)
          const allValues2 = bin.values.map(({ value }) => value)

          if (arrayEquality(allValues1, allValues2)) {
            const values = thisBin.values.map((valueObject) => {
              if (clearAll) return { ...valueObject, enabled: false }
              if (selectAll) return { ...valueObject, enabled: true }
              if (valueObject.value !== e?.target?.value) return valueObject
              return { ...valueObject, enabled: !valueObject.enabled }
            })
            return { ...thisBin, values, enabled: true }
          }
          return { ...thisBin, enabled: false }
        })

        const enabled =
          type === "setAvailableFilters" ? true : characteristic.enabled
        return { ...characteristic, enabled, bins }
      }
    )
    dispatch({
      type,
      payload: { availableCharacteristics: newCharacteristics }
    })
  }

  if (isFilter && isNumeric) {
    return <AddClassFilter column={column} {...props} />
  }

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      background="white"
      p="0.25em"
      m="0.25em"
      border="1px solid lightgrey"
      w="100%"
    >
      {!isNumeric && (
        <Flex w="100%" mt={1} justify={"space-between"} gridGap="1em">
          <Button
            colorScheme="green"
            size="xs"
            onClick={(e) => onChange({ selectAll: true })}
          >
            Select All
          </Button>
          <Button
            colorScheme="red"
            size="xs"
            onClick={(e) => onChange({ clearAll: true })}
          >
            Clear All
          </Button>
        </Flex>
      )}
      {sortedValues.map(({ value, enabled }, index, array) => {
        if (nullValues.includes(value)) return null
        const nextBin = array[index + 1]?.value
        if (nextBin === undefined && isNumeric) return null
        if (isNumeric) {
          const label = bin.valueNames.length ? bin.valueNames[index] : ""
          return (
            <Flex
              key={`${column}_${value} - ${nextBin}`}
              w="100%"
              justify="space-between"
            >
              <Box>{`${value} - ${nextBin}`}</Box>
              <Box>{label}</Box>
            </Flex>
          )
        }
        return (
          <Box p={"0 0.5rem"} key={`${column}_${value}`}>
            <input
              id={`${column}_${value}`}
              style={{ margin: "0.1em 0.25em 0 0" }}
              type="checkBox"
              onChange={(e) => onChange({ e })}
              name={column}
              value={value}
              checked={enabled}
            />

            <label htmlFor={`${column}_${value}`}>{value}</label>
          </Box>
        )
      })}
    </Flex>
  )
}

import React, { FC } from "react"
import { Flex, Tooltip, IconButton, Spinner, Box } from "@chakra-ui/react"

import { SpinnerBox } from "../SpinnerBox"

export const ShowSpinner = () => {
  return (
    <Flex w="100%" h="50%" alignSelf="center" mb="3px" justify="center">
      <SpinnerBox>
        <Spinner
          size="lg"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
        />
      </SpinnerBox>
    </Flex>
  )
}

import { schemaType, stateType } from "./interfaces"
import { characteristicsInterface } from "./types"

export const windowExists = typeof window !== "undefined"

export const port =
  windowExists && window.location.hostname === "localhost" ? ":8080" : ""
export const workspace = "workspace"

export const protocol = windowExists ? window.location.protocol : "http:"
export const hostName = windowExists ? window.location.hostname : "localhost"

export const flaskUrl = `${protocol}//${hostName}/int/`

export const download_url = `${protocol}//${hostName}${port}/geoserver/ows`

export const nullValue = "-99999"

export const path = "/geoserver/gwc/service/tms/1.0.0/"

export const parameters = "@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf"

export const emptySchema: schemaType = {
  nodes: [],
  links: []
}

export const initial: stateType = {
  show: "/",
  message: "",
  availableCharacteristics: [],
  filters: [],
  originalFilters: [],
  loading: false,
  schemaData: {
    nodes: [],
    links: []
  },
  modalMessage: "",
  modal: "",
  reset: false
}

export const expandColor = "green"

export const linkColor = "#145c89"

export const headerHeight = "4.6875em"

export const colorPalette = [
  "#EFF2F2",
  "#78ABC7",
  "#373C6D",
  "#8291B7",
  "#131846",
  "rgb(31,120,180)"
]

export const pastelColors = [
  "rgb(141,211,199)",
  "rgb(190,186,218)",
  "rgb(251,128,114)",
  "rgb(128,177,211)",
  "rgb(253,180,98)",
  "rgb(179,222,105)",
  "rgb(217,217,217)",
  "rgb(188,128,189)",
  "rgb(204,235,197)",
  "rgb(255,237,111)",
  "rgb(166,206,227)",
  "rgb(31,120,180)",
  "rgb(178,223,138)",
  "rgb(51,160,44)",
  "rgb(227,26,28)"
]

export const tooLargeString =
  "The query result is too large. Please narrow your search"

import React from "react"
import { Flex, Box } from "@chakra-ui/react"
import { characteristicsInterface } from "../common/types"
import { DroppableContainer } from "./DroppableContainer"
import { standardProps } from "../common/interfaces"
import ThemeList from "./ThemeList"

type propTypes = standardProps & {
  list: characteristicsInterface[]
}

export default function UnselectedDroppableInstance(props: propTypes) {
  const { list: sortedList } = props
  const droppableId = "unselected"
  const themesArray = sortedList.map(({ theme }) => theme)
  const themes = [...new Set(themesArray)].sort()

  return (
    <DroppableContainer droppableId={droppableId}>
      <Box
        h="100%"
        w="100%"
        overflowY="scroll"
        boxShadow="0.1875em 0.1875em 0.2em 0 rgba(0,0,0,0.5)"
        mb="0.4em"
      >
        <Flex
          direction="column"
          p="1em"
          w="100%"
          border="1px solid lightgray"
          background="hsl(183, 1%, 99%)"
        >
          {themes.map((theme) => (
            <ThemeList
              key={theme}
              theme={theme}
              droppableId={droppableId}
              {...props}
            />
          ))}
        </Flex>
      </Box>
    </DroppableContainer>
  )
}

import React, { useState } from "react"
import { Button, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react"
import {
  downloadFormatOptions,
  formatOption,
  handleDownload
} from "./functions"
import { standardProps } from "../../common/interfaces"
import CustomCard from "../../common/CustomCard"
import { getAllIds } from "../../common/functions"
import { layerName } from "../Map/constants"

export const Download = (props: standardProps) => {
  const { state, dispatch } = props
  const { loading, schemaData } = state

  const selectedFeatures = getAllIds(schemaData)

  const [downloadFormat, setDownloadFormat] = useState<formatOption>(
    downloadFormatOptions[0]
  )

  const changeFormat = (e: any) => {
    const newFormat = downloadFormatOptions.find(
      ({ display }) => display === e.target.value
    )
    if (!newFormat) return
    setDownloadFormat(newFormat)
  }

  return (
    <Flex direction="column" alignItems="center" justify="center">
      <CustomCard
        style={{ width: "150px" }}
        innerStyle={{
          padding: "5px"
        }}
        title="Format"
      >
        <RadioGroup value={downloadFormat.display}>
          <Flex direction="column" alignItems="flex-start" ml="1em">
            {downloadFormatOptions.map(({ display }) => (
              <Radio
                key={display}
                onChange={changeFormat}
                bg="lightgray"
                value={display}
              >
                {display}
              </Radio>
            ))}
          </Flex>
        </RadioGroup>
      </CustomCard>

      <Text mt="1em">
        Select a format for downloading selected features. Results include the
        identifiers of the dams, location information, and values of selected
        characteristics in the current version of the NPD data set.
      </Text>

      <Button
        colorScheme="green"
        mt="1em"
        w="min-content"
        isDisabled={loading || !selectedFeatures.length}
        onClick={() => {
          handleDownload(selectedFeatures, downloadFormat, layerName, dispatch)
        }}
      >
        Download
      </Button>
    </Flex>
  )
}

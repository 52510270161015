import React, { Dispatch, FC, SetStateAction } from "react"
import CustomCard from "../../common/CustomCard"
import { Flex, Select, Box } from "@chakra-ui/react"
import { attributeType, dispatchType } from "../../common/interfaces"
import { InfoIconButton } from "../../common/InfoIconButton"

type propTypes = {
  selectedAttribute: string
  setSelectedAttribute: Dispatch<SetStateAction<string>>
  attributeList: attributeType[]
  dispatch: dispatchType
}

export const Attribute: FC<propTypes> = (props) => {
  const { selectedAttribute, setSelectedAttribute, attributeList, dispatch } =
    props

  const { humanName } = attributeList.find(
    ({ columnName }) => columnName === selectedAttribute
  )!

  const themeArray = attributeList.map(({ theme }) => theme)
  const themes = [...new Set(themeArray)].sort()

  return (
    <CustomCard
      style={{ width: "200px", background: "white" }}
      title={"Attribute"}
    >
      <Flex justify="space-around">
        <Select
          borderColor="gray.200"
          m={"auto"}
          w="80%"
          value={selectedAttribute}
          onChange={(e: any) => {
            setSelectedAttribute(e.target.value)
          }}
        >
          {themes.map((themeName) => {
            return (
              <optgroup key={themeName} label={themeName}>
                {attributeList.map(({ columnName, humanName, theme }) => {
                  if (theme !== themeName) return null
                  return (
                    <option
                      key={`${columnName}_${themeName}`}
                      value={columnName}
                    >
                      {humanName}
                    </option>
                  )
                })}
              </optgroup>
            )
          })}
        </Select>
        <Box margin="auto">
          <InfoIconButton dispatch={dispatch} modalMessage={humanName} />
        </Box>
      </Flex>
    </CustomCard>
  )
}

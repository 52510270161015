import React, { FC } from "react"
import { Heading, Flex } from "@chakra-ui/react"

export const Brand: FC = (props: any) => {
  return (
    <Flex direction="column" h="100%" justify="center">
      <Heading color="white" fontSize={["sm", "md", "xl", "xl"]}>
        NPDamCAT
      </Heading>
    </Flex>
  )
}

import React from "react"
import { Text, Flex, Box } from "@chakra-ui/react"

type propTypes = {
  message: string
}

export const NoResults = (props: propTypes) => {
  return (
    <Flex direction="column" alignItems="center" p="1em">
      {props.message}
    </Flex>
  )
}

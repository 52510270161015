import React, { useState } from "react"
import { standardProps } from "../common/interfaces"
import {
  Flex,
  NumberInput,
  NumberInputField,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
  Text
} from "@chakra-ui/react"
import AddNewClassButtons from "./AddNewClassButtons"
import { getDataSource } from "../common/functions"

type propTypes = standardProps & {
  isFilter?: boolean
}

export default function AddNewClass(props: propTypes) {
  const { state } = props
  const { modalMessage } = state

  const { targetCharacteristic, firstDefault, lastDefault, values } =
    getDataSource(state, modalMessage)

  const [array, setArray] = useState(values.map((i) => i.toString()))

  const onChange = (value: string, index: number) => {
    setArray((oldArray) =>
      oldArray.map((oldValue, oldIndex) =>
        oldIndex === index ? value : oldValue
      )
    )
  }

  return (
    <Flex direction="column" h="20em" alignItems="center">
      <Flex
        direction="column"
        h="100%"
        alignItems="center"
        overflow="auto"
        m="1em"
      >
        {array.map((number, index) => {
          return (
            <Flex justify="space-between" key={`${modalMessage}_${index}`}>
              <Text mt="0.25em" mr="0.5em">
                {index + 1}.
              </Text>
              <NumberInput
                onChange={(value) => onChange(value, index)}
                value={number}
                size="sm"
                w="100%"
                borderColor="darkgray"
                mb="1em"
                min={firstDefault}
                max={lastDefault}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
          )
        })}
      </Flex>

      <AddNewClassButtons
        array={array}
        setArray={setArray}
        characteristic={targetCharacteristic}
        values={values}
        {...props}
      />
    </Flex>
  )
}

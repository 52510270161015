import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  Heading,
  Box
} from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import { closeModal } from "../common/functions"

export const CharacteristicsInfoModal = (props: standardProps) => {
  const { state, dispatch } = props
  const { availableCharacteristics, modalMessage } = state

  const characteristic = availableCharacteristics.find(
    ({ name }) => name === modalMessage
  )

  return (
    <Modal
      isOpen={state.modal === "characteristicsInfoModal"}
      onClose={() => closeModal(dispatch)}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {characteristic?.name || "Info"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex maxH="70vh" overflowY="auto" direction="column" w="100%">
            <Flex
              direction="column"
              alignItems="flex-start"
              m="1em"
              gridGap="2em"
            >
              <Box>
                <Box fontWeight="600" as="span">
                  Ordering groups/sub-groups:{" "}
                </Box>
                Suppose you want to create groups based primarily on dam
                purpose, but then you want to explore sub-groups based on
                architectural type. You would need to make sure the first item
                in the “Selected” side is “Purpose”, and the second is
                “Architectural/Structural type”.
              </Box>

              <Box>
                <Box fontWeight="600" as="span">
                  Filtering dams:{" "}
                </Box>
                For classifying a specific subset of dams, click on the filter
                icon to specify the values you wish to include. For example, if
                you want to only classify dams in the eastern US (e.g.,
                Hydrologic Regions 01-03), click on the filter icon next to
                “Hydrologic Region” and only select “1”, “2”, and “3” in the
                values, and enable the filter.
              </Box>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

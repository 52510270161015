import React, { FC } from "react"
import CustomCard from "../common/CustomCard"

export const TableCard: FC = (props) => {
  const { children } = props
  return (
    <CustomCard
      style={{
        zIndex: 9999,
        height: "min-content",
        maxHeight: "50%",
        minWidth: "0"
      }}
      innerStyle={{
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden"
      }}
      title={`Attribute Table`}
    >
      {children}
    </CustomCard>
  )
}

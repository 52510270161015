import React, { Dispatch, SetStateAction } from "react"
import { standardProps } from "../common/interfaces"
import { Flex } from "@chakra-ui/react"
import { ShowDataSources } from "./ShowDataSources"
import CustomCard from "../common/CustomCard"
import { characteristicsInterface } from "../common/types"
import { getFilterObject } from "../common/functions"
import { InfoIconButton } from "../common/InfoIconButton"

type propsType = standardProps & {
  filterCharacteristic?: characteristicsInterface
  disabled?: boolean
  setDisabled?: Dispatch<SetStateAction<boolean>>
}

export const ShowClasses = (props: propsType) => {
  const { state, filterCharacteristic } = props
  const { availableCharacteristics } = state
  const characteristics = filterCharacteristic
    ? [filterCharacteristic]
    : availableCharacteristics

  const { overflowX, isFilter } = getFilterObject(state)

  return (
    <Flex h="100%" margin="auto" w="min-content" style={{ gap: "1em" }}>
      {characteristics.map((characteristic) => {
        if (!characteristic.enabled && !isFilter) return null
        return (
          <CustomCard
            key={characteristic.name}
            style={{
              height: "100%",
              width: "min-content",
              margin: "auto",
              minWidth: "12em"
            }}
            innerStyle={{
              padding: "1em",
              height: "100%",
              overflow: "auto"
            }}
            customIcon={
              <InfoIconButton
                {...props}
                modalMessage={characteristic.name}
                colorScheme="white"
              />
            }
            iconType="info"
            title={characteristic.name}
          >
            <ShowDataSources {...props} characteristic={characteristic} />
          </CustomCard>
        )
      })}
    </Flex>
  )
}

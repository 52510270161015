import React, { FC, useState } from "react"
import { standardProps } from "../common/interfaces"
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react"
import { TaxonomyDiagram } from "./TaxonomyDiagram/TaxonomyDiagram"
import { fetchHelper } from "../common/fetchFunctions"
import { damDataType, fullResult } from "./TaxonomyDiagram/types"
import { ShowSpinner } from "./TaxonomyDiagram/ShowSpinner"
import { NoResults } from "./TaxonomyDiagram/NoResults"
import { emptySchema } from "../common/constants"
import { useEffect } from "react"
import { getCharacteristics } from "./functions"
import { RenderVisuals } from "./RenderVisuals"

export const ResultsPage: FC<standardProps> = (props) => {
  const { state, dispatch } = props
  const { availableCharacteristics } = state
  const [results, setResults] = useState<fullResult | null>(null)
  const [error, setError] = useState<string>("")
  const [tabIndex, setTabIndex] = useState(0)
  const [damData, setDamData] = useState<damDataType | null>(null)
  const [damError, setDamError] = useState<string>("")

  const enabledCharacteristics = availableCharacteristics.filter(
    ({ enabled }) => enabled
  )

  const enabledFilters = state.filters.filter(({ enabled }) => enabled)
  const characteristics = getCharacteristics(enabledCharacteristics)

  const filters = getCharacteristics(enabledFilters)

  useEffect(() => {
    dispatch({
      type: "setSchemaData",
      payload: emptySchema
    })
    fetchHelper("getresults", {
      characteristics,
      filters
    }).then((a: fullResult | string) => {
      if (typeof a === "string") return setError(a)
      setResults(a)
    })
  }, [])

  useEffect(() => {
    if (tabIndex === 0 && damData) {
      setDamData(null)
      setDamError("")
    }
  }, [tabIndex])

  console.info(results)

  if (error) return <NoResults message={error} />

  if (!results) return <ShowSpinner />

  if (!enabledCharacteristics.length)
    return <NoResults message={"No Results Found"} />

  const renderVisualsProps = {
    ...props,
    tabIndex,
    damData,
    setDamData,
    damError,
    setDamError
  }

  return (
    <Flex h="100%" direction="column" alignItems="center" w="100%">
      <Flex overflow="hidden" w="100%" h="100%" alignItems="center">
        <Tabs
          isLazy
          variant="enclosed"
          colorScheme="blue"
          w="100%"
          h="100%"
          isFitted
          onChange={(index) => setTabIndex(index)}
        >
          <TabList m="0.25em 0.25em 0 0.25em">
            <Tab>Taxonomy Diagram</Tab>
            <Tab>Map</Tab>
            <Tab>Group Characteristics</Tab>
          </TabList>
          <TabPanels h="100%" w="100%">
            <TabPanel h="90%" w="100%">
              <TaxonomyDiagram results={results.result} {...props} />
            </TabPanel>
            <TabPanel h="90%" w="100%">
              <RenderVisuals {...renderVisualsProps} />
            </TabPanel>
            <TabPanel h="90%" w="100%">
              <RenderVisuals {...renderVisualsProps} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Flex>
  )
}

import React, { FC } from "react"
import { Box } from "@chakra-ui/react"

export const SpinnerBox: FC<{}> = (props) => {
  return (
    <Box h="20px" w="20px" m="auto 13px auto 0">
      {props.children}
    </Box>
  )
}

import { characteristicsInterface } from "./../common/types"

export const getCharacteristics = (
  enabledCharacteristics: characteristicsInterface[]
) => {
  return enabledCharacteristics.map((characteristic) => {
    const bin = characteristic.bins.find((bin) => bin.enabled)

    return { ...characteristic, bin }
  })
}

import React, { FC } from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { BarChartElements } from "./BarChartElements"
import { standardProps } from "../../common/interfaces"
import { useBarChart } from "./useBarChart"
import { damDataType } from "../TaxonomyDiagram/types"
import { BarChart } from "./BarChart"

type propTypes = standardProps & {
  damData: damDataType
}

export const RenderBarChart: FC<propTypes> = (props) => {
  const barChartProperties = useBarChart(props)
  const { data, options, selectedAttribute } = barChartProperties

  return (
    <Flex direction="column" h="100%">
      <Box h="100%">
        {data.labels.length > 100000 ? (
          <Heading h="min-content" size="sm" mt="1em">
            {`Too many bins to create chart.`}
          </Heading>
        ) : (
          <BarChart
            data={data}
            options={options}
            selectedAttribute={selectedAttribute}
          />
        )}
      </Box>
      <Box h="min-content">
        <BarChartElements {...props} {...barChartProperties} />
      </Box>
      <Box h="2em" />
    </Flex>
  )
}

import React, { useEffect, useRef, useState } from "react"
import { Box, Text, Flex, Heading } from "@chakra-ui/react"
import Diagram, { createSchema, useSchema } from "beautiful-react-diagrams"
import "beautiful-react-diagrams/styles.css"
import { getNodes } from "./getNodes"
import { colorPalette } from "../../common/constants"
import { stateType, dispatchType, schemaType } from "../../common/interfaces"
import { resultObjectType } from "./types"
import { DiagramSchema } from "beautiful-react-diagrams/@types/DiagramSchema"
import { getAllIds } from "../../common/functions"

type propTypes = {
  state: stateType
  dispatch: dispatchType
  results: resultObjectType
}

export const TaxonomyDiagram = (props: propTypes) => {
  const { results, state, dispatch } = props
  const { availableCharacteristics, schemaData } = state
  const schemaDataFormatted = schemaData as Partial<DiagramSchema<unknown>>

  const schemaRef = useRef<HTMLDivElement>(null)
  const boxRef = useRef<HTMLDivElement>(null)

  const enabledCharacteristics = availableCharacteristics.filter(
    ({ enabled }) => enabled
  )

  const lastChar =
    enabledCharacteristics[enabledCharacteristics.length - 1].name
  const totalBins = results.characteristics[lastChar].bins.length

  const [rendered, setRendered] = useState<boolean>(false)

  const initialSchema = createSchema(schemaDataFormatted)
  const [schema, { onChange }] = useSchema(initialSchema)

  useEffect(() => {
    if (schema.nodes.length) return
    const payload: schemaType = getNodes(
      results,
      enabledCharacteristics,
      totalBins,
      dispatch
    )
    dispatch({
      type: "setSchemaData",
      payload
    })
  }, [])

  //Render schema after a click
  useEffect(() => {
    const newSchema = createSchema(schemaDataFormatted)
    onChange(newSchema)
    if (!rendered) setRendered(true)
  }, [schemaData])

  //Set scroll halfway down diagram
  // useEffect(() => {
  //   if (!schemaRef?.current || !boxRef?.current) return
  //   boxRef.current.scrollTop =
  //     schemaRef.current.offsetHeight / 2 - boxRef.current.offsetHeight
  // }, [rendered])

  if (!schemaData.nodes.length || !schemaData.links.length)
    return (
      <Heading h="min-content" size="sm" mt="1em">
        Your query yielded no results. You may have set too many filters.
      </Heading>
    )

  return (
    <Flex direction="column" h="100%">
      <Box
        id="DBox"
        overflow="auto"
        bg={colorPalette[0]}
        border="1px solid black"
        h="100%"
        w="100%"
        ref={boxRef}
      >
        <Box
          h={`${totalBins * 10}em`}
          w={`${enabledCharacteristics.length * 20}em`}
          minW="100%"
          minH="100%"
          ref={schemaRef}
        >
          <Diagram
            schema={schema}
            onChange={onChange}
            // style={{ overflow: "unset" }}
          />
        </Box>
      </Box>

      <Text h="min-content" mt="1em">
        View the makeup of NPDs based on selections made in the previous steps.
        The number of dams in each sub-group is displayed in each box. To
        explore specific sub-groups of dams, click on any number of boxes in the
        taxonomy diagram, and then view the results in the “Map” and “Group
        Characteristics” tabs. Click a box again to unselect.{" "}
      </Text>
    </Flex>
  )
}

import React, { useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Box
} from "@chakra-ui/react"
import { standardProps } from "../common/interfaces"
import { ShowClasses } from "../Classes/ShowClasses"
import { closeModal } from "../common/functions"
import { AddFilterButtons } from "./AddFilterButtons"
import "./filterModal.css"

export const AddFilterModal = (props: standardProps) => {
  const { state, dispatch } = props
  const { filters, modalMessage } = state

  const characteristic =
    filters.find(({ name }) => name === modalMessage) || filters[0]

  const [disabled, setDisabled] = useState(false)

  return (
    <Modal
      isOpen={state.modal === "addFilterModal"}
      onClose={() => closeModal(dispatch)}
      isCentered
      size="4xl"
      id="filter"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign="center"
          // background="unset"
        >
          Add New Filter
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" w="100%">
            <Box h="26em" alignSelf="center">
              <ShowClasses
                {...props}
                filterCharacteristic={characteristic}
                disabled={disabled}
                setDisabled={setDisabled}
              />
            </Box>

            <AddFilterButtons
              {...props}
              filterCharacteristic={characteristic}
              disabled={disabled}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import { dispatchType } from "./../../common/interfaces"
import { characteristicsInterface } from "./../../common/types"
import { resultObjectType } from "./types"
import { CustomNode } from "./CustomNode"
import { pastelColors } from "../../common/constants"
import { nodeType, linkType } from "../../common/interfaces"

const xBump = 220
const yBump = 150
const add = 80
const multiply = 0.6

export const getNodes = (
  results: resultObjectType,
  enabledCharacteristics: characteristicsInterface[],
  totalBins: number,
  dispatch: dispatchType
) => {
  const updateNodes = (id: string) => {
    dispatch({
      type: "updateSchemaData",
      payload: id
    })
  }
  let x = 10
  const acc: number[] = []

  const { bins } = results.characteristics[enabledCharacteristics[0].name]

  const allBinFeatures = bins.reduce(
    (acc, { features }) => [...acc, ...features],
    acc
  )

  const nodes: nodeType[] = [
    {
      id: "total",
      render: CustomNode,
      coordinates: [x, totalBins * yBump * multiply + add],
      data: {
        color: pastelColors[0],
        name: "Total",
        binString: "",
        count: results.total,
        selected: false,
        features: allBinFeatures,
        id: "total"
        // updateNodes
      }
    }
  ]
  const links: linkType[] = []

  return enabledCharacteristics.reduce(
    (acc, { name, bins, isNumeric }, i) => {
      x = x + xBump

      const selectedBin = bins.find(({ enabled }) => enabled)!

      // Add No Data at top
      acc.nodes.push({
        id: `${name}NoData`,
        coordinates: [x, 10],
        render: CustomNode,
        data: {
          color: "pink",
          name,
          binString: `No Data`,
          count: results.characteristics[name].noData,
          selected: false,
          features: [],
          id: `${name}NoData`
          // updateNodes
        }
      })

      const colorIndex =
        enabledCharacteristics.findIndex((i) => name === i.name) + 1

      const targetResult = results.characteristics[name].bins
      const common = targetResult.filter(
        ({ bin }) => bin === targetResult[0].bin
      )
      const numberOfDivisions = targetResult.length / common.length

      targetResult.forEach(({ bin, features }, index, array) => {
        const id = `${name}${index}`

        const thisBinIndex = selectedBin.values.findIndex(
          (i) => i.value === bin
        )
        const nextBin = isNumeric
          ? selectedBin.values[thisBinIndex + 1]["value"]
          : ""
        const nextBinString = nextBin || nextBin === 0 ? ` - ${nextBin}` : ""
        const label = selectedBin.valueNames[index] || ""
        if (features.length >= 0) {
          acc.nodes.push({
            id,
            coordinates: [
              x,
              (totalBins / array.length) * yBump * (index + multiply) + add
            ],
            render: CustomNode,
            data: {
              color: pastelColors[colorIndex],
              name,
              id,
              label,
              binString: `${bin}${nextBinString}`,
              count: features.length,
              features,
              selected: false,
              updateNodes
            }
          })

          const group = Math.floor(index / numberOfDivisions)
          const previousName = enabledCharacteristics[i - 1]?.name
          const input = i ? `${previousName}${group}` : "total"

          acc.links.push({ input, output: id, readonly: true })
        }
      })

      return acc
    },
    { nodes, links }
  )
}

import { useState } from "react"
import { getAttributeList } from "../../common/functions"
import { standardProps } from "../../common/interfaces"
import { damDataType } from "../TaxonomyDiagram/types"
import { getCategoryData, getOptions, sortArray } from "./functions"

type propTypes = standardProps & {
  damData: damDataType
}

export const useBarChart = (props: propTypes) => {
  const { state, damData } = props

  const attributeList = getAttributeList(state)

  const first = state.availableCharacteristics.find(({ enabled }) => enabled)
  const [selectedAttribute, setSelectedAttribute] = useState(
    first?.column || attributeList[0].columnName
  )

  const attribute =
    attributeList.find(({ columnName }) => columnName === selectedAttribute) ||
    attributeList[0]

  const { isNumeric, units } = attribute

  const func = isNumeric ? sortArray : getCategoryData
  const { sortedArray, labels } = func({ selectedAttribute, damData })

  const sum =
    damData.length < 1 || !isNumeric
      ? NaN
      : sortedArray.reduce((acc: number, c: string) => acc + +c, 0)
  const average = sum / sortedArray.length
  const data = {
    labels,
    datasets: [
      {
        backgroundColor: "rgb(34, 118, 172)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(250,251,69,0.4)",
        hoverBorderColor: "rgba(0,0,0,1)",
        minBarLength: 2,
        maxBarThickness: 50,
        data: sortedArray
      }
    ]
  }
  const options = getOptions(attribute)

  return {
    selectedAttribute,
    setSelectedAttribute,
    average,
    data,
    options,
    units,
    sum,
    isNumeric,
    attributeList
  }
}

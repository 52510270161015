import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useRef,
  useState
} from "react"
import { standardProps } from "../../common/interfaces"
import { DataMap } from "./DataMap"
import { getAllIds } from "../../common/functions"
import { NoSelection } from "../NoSelection"
import { Table } from "../../Table/Table"
import { Button, Flex, Grid } from "@chakra-ui/react"
import { damDataType } from "../TaxonomyDiagram/types"
import { useEffect } from "react"
import { highlightColor, initialStyle } from "./constants"

type propTypes = standardProps & {
  damData: damDataType
  setSelectedPoints: Dispatch<SetStateAction<number[]>>
  leafletLayersRef: MutableRefObject<L.CircleMarker[]>
}

export const MapButtons = (props: propTypes) => {
  const { setSelectedPoints, leafletLayersRef, damData } = props

  return (
    <Flex direction="column" w="6em" p="0 0.5em" gridGap="0.5em">
      <Button
        colorScheme="green"
        size="sm"
        w="100%"
        pointerEvents="all"
        onClick={() => {
          setSelectedPoints(damData.map(({ id }) => id))
          leafletLayersRef.current.forEach((i) => {
            i.setStyle({ ...initialStyle, color: highlightColor })
          })
        }}
      >
        Select All
      </Button>
      <Button
        colorScheme="red"
        size="sm"
        w="100%"
        pointerEvents="all"
        onClick={() => {
          setSelectedPoints([])
          leafletLayersRef.current.forEach((i) => {
            i.setStyle(initialStyle)
          })
        }}
      >
        Clear All
      </Button>
    </Flex>
  )
}

import React, { Dispatch, useState, CSSProperties } from "react"
import { Flex, Tooltip, IconButton, Box } from "@chakra-ui/react"
import { colorPalette, expandColor } from "./constants"
import { FiPlus, FiMinus } from "react-icons/fi"
import { IoMdClose } from "react-icons/io"

type customCardProps = {
  iconClick?: (event: React.MouseEvent<any, MouseEvent>) => void
  iconType?: "close" | "expand" | "info"
  hideBody?: boolean
  title: string
  tooltip?: string
  style?: CSSProperties
  innerStyle?: CSSProperties
  children?: any
  id?: string
  headerColor?: string
  customIcon?: any
}

interface CardHeaderProps extends customCardProps {
  hidden: boolean
  setHidden: Dispatch<React.SetStateAction<boolean>>
  setClosed: Dispatch<React.SetStateAction<boolean>>
}

interface HeaderIconProps extends CardHeaderProps {
  visibility: "hidden" | "inherit"
}

const HeaderIcon = (props: HeaderIconProps) => {
  const { visibility, hidden, iconType, setHidden, setClosed, customIcon } =
    props
  const isExpand = iconType === "expand"
  const isClose = iconType === "close"
  if (customIcon && visibility === "inherit") return customIcon

  return (
    <IconButton
      aria-label={isExpand ? "Expand" : "Close"}
      colorScheme={isExpand ? expandColor : "red"}
      icon={
        isExpand ? (
          hidden ? (
            <FiPlus size="18px" />
          ) : (
            <FiMinus size="18px" />
          )
        ) : (
          <IoMdClose />
        )
      }
      size="xs"
      visibility={visibility}
      onClick={() => {
        // if (iconClick) iconClick(e)
        if (isExpand) setHidden((old) => !old)
        if (isClose) setClosed(true)
      }}
    />
  )
}

function CardHeader(props: CardHeaderProps) {
  const { title, tooltip, iconType, headerColor } = props
  const myHeader = <h5>{title}</h5>
  const myFlex = (
    <Flex
      justify={"space-between"}
      p={1}
      borderRadius="0.5em 0.5em 0 0"
      color="white"
      fontWeight="600"
      whiteSpace="nowrap"
      backgroundColor={headerColor || colorPalette[2]}
    >
      {<HeaderIcon visibility={"hidden"} {...props} />}
      {myHeader}
      {<HeaderIcon visibility={iconType ? "inherit" : "hidden"} {...props} />}
    </Flex>
  )
  if (tooltip) {
    return (
      <Tooltip
        zIndex={1000}
        placement={"top"}
        aria-label={tooltip || title}
        label={tooltip}
      >
        {myFlex}
      </Tooltip>
    )
  }
  return myFlex
}

export default function CustomCard(props: customCardProps) {
  const { hideBody, title } = props

  const [hidden, setHidden] = useState(!!hideBody)
  const [closed, setClosed] = useState(false)

  const innerStyle = props.innerStyle || {}
  const bodyStyle: CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...innerStyle,
    paddingBottom: innerStyle.paddingBottom || innerStyle.padding || "0.2rem",
    paddingTop: innerStyle.paddingTop || innerStyle.padding || "0.2rem"
  }
  return (
    <Flex
      zIndex={1000}
      visibility={closed ? "hidden" : "inherit"}
      direction="column"
      w="100%"
      title={""}
      style={{
        border: "0.0625em solid lightgrey",
        borderRadius: "8px",
        width: "100%",
        textAlign: "center",
        height: "min-content",
        background: colorPalette[0],
        pointerEvents: "auto",
        ...props.style
      }}
    >
      <CardHeader
        {...props}
        setClosed={setClosed}
        hidden={hidden}
        setHidden={setHidden}
      />

      <Box id={props.id || props.title} style={bodyStyle}>
        {!hidden && props.children}
      </Box>
    </Flex>
  )
}

import React, { Dispatch, FC, SetStateAction } from "react"
import { Flex } from "@chakra-ui/react"
import { Attribute } from "./Attribute"
import { ShowStat } from "./ShowStat"
import { attributeType, dispatchType } from "../../common/interfaces"

type propTypes = {
  average: number
  sum: number
  units: string
  selectedAttribute: string
  setSelectedAttribute: Dispatch<SetStateAction<string>>
  attributeList: attributeType[]
  dispatch: dispatchType
}

export const BarChartElements: FC<propTypes> = (props) => {
  return (
    <Flex
      w="100%"
      p="0 2em"
      alignItems="center"
      justify="space-between"
      style={{ height: "min-content" }}
    >
      <ShowStat {...props} statType={"Sum"} value={props.sum} />
      <ShowStat {...props} statType={"Average"} value={props.average} />
      <Attribute {...props} />
    </Flex>
  )
}

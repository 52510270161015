import React from "react"
import { Button } from "@chakra-ui/react"
import { standardProps } from "./interfaces"
import { buttonDirectionType, displayObject } from "../displayObject"

type propsType = standardProps & { direction: buttonDirectionType }

export default function ContinueButton(props: propsType) {
  const { state, dispatch, direction } = props
  const { show } = state
  const thisPage = displayObject.findIndex(({ name }) => name === show)
  const navigate = direction === "Continue" ? 1 : -1
  const target = displayObject[thisPage + navigate]
  const { checkDisabled } = displayObject[thisPage]
  const isDisabled = checkDisabled ? checkDisabled(state, direction) : false
  const payload = target?.name || "/"

  const autoFocus = thisPage === 0 && direction === "Continue"

  return (
    <Button
      autoFocus={autoFocus}
      pointerEvents="all"
      w="8em"
      mt="0.625em"
      colorScheme={"blue"}
      isDisabled={!target || isDisabled}
      alignSelf="flex-end"
      onClick={() => {
        sessionStorage.setItem(
          "npd_state",
          JSON.stringify({ ...state, show: payload })
        )
        dispatch({ type: "setShow", payload })
      }}
    >
      {direction}
    </Button>
  )
}

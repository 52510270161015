import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react"
import { standardProps } from "../common/interfaces"
import { Flex, Text, Input, Icon, Box } from "@chakra-ui/react"
import {
  capitalizeFirstLetter,
  getDataSource,
  getDispatchType
} from "../common/functions"
import { binType, characteristicsInterface } from "../common/types"
import { setAllBins } from "./functions"
import { BiErrorCircle } from "react-icons/bi"

type propTypes = standardProps & {
  column: string
  disabled?: boolean
  setDisabled?: Dispatch<SetStateAction<boolean>>
}

export const AddClassFilter = (props: propTypes) => {
  const { state, column, dispatch, disabled, setDisabled } = props
  const { filters } = state
  const type = getDispatchType(state)

  const { filterCharacteristic, firstDefault, lastDefault, values } =
    getDataSource(state, column)

  console.log({ filterCharacteristic })

  const filterValues = filterCharacteristic.bins[0].values
  const finalValues = [
    filterValues[0].value,
    filterValues[filterValues.length - 1].value
  ]

  const [stringValues, setStringValues] = useState([
    finalValues[0].toString(),
    finalValues[1].toString()
  ])

  const inverted = Number(stringValues[0]) > Number(stringValues[1])

  if (disabled === false && setDisabled) {
    if (
      inverted ||
      isNaN(Number(stringValues[0])) ||
      isNaN(Number(stringValues[1]))
    ) {
      setDisabled(true)
    }
  }

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    minOrMax: "max" | "min"
  ) => {
    const { value } = e.target
    const re = new RegExp("^[1234567890.-]*$")
    const ok = re.exec(value)
    if (ok === null) return
    const stringArray =
      minOrMax === "min" ? [value, stringValues[1]] : [stringValues[0], value]
    setStringValues(stringArray)
    const newArray = stringArray.map(Number)

    const isInvalid = isNaN(newArray[0]) || isNaN(newArray[1])
    if (!isInvalid) {
      confirmChange(newArray)
      setDisabled && setDisabled(false)
    }
  }

  const confirmChange = (newArray: number[]) => {
    const values = newArray.map((value) => ({ value, enabled: true }))
    const newUserBin: binType = {
      userDefined: true,
      enabled: true,
      values,
      valueNames: []
    }
    const newCharacteristics: characteristicsInterface[] = filters.map(
      (characteristic) => {
        if (filterCharacteristic.name !== characteristic.name)
          return characteristic
        if (filterCharacteristic.column !== characteristic.column) {
          return {
            ...characteristic,
            bins: setAllBins(characteristic.bins, false)
          }
        }
        return { ...characteristic, bins: [newUserBin] }
      }
    )
    dispatch({
      type,
      payload: { availableCharacteristics: newCharacteristics }
    })
  }

  return (
    <Flex direction="column" h="100%" alignItems="center" id="hi">
      <Flex
        direction="column"
        h="100%"
        alignItems="center"
        overflow="auto"
        m="1em"
      >
        {stringValues.map((stringValue, index) => {
          const minOrMax = index ? "max" : "min"
          return (
            <Flex justify="space-between" key={`${column}_${index}`}>
              <Text mt="0.25em" mr="0.5em">
                {`${capitalizeFirstLetter(minOrMax)}:`}
              </Text>
              <Input
                onChange={(e) => onChange(e, minOrMax)}
                value={stringValue}
                size="sm"
                w="100%"
                minW="6em"
                borderColor="darkgray"
                mb="1em"
              ></Input>
              <Box p="3px">
                <Icon
                  title="Invalid Value"
                  visibility={
                    isNaN(Number(stringValue)) || inverted
                      ? "visible"
                      : "hidden"
                  }
                  as={BiErrorCircle}
                  w={6}
                  h={6}
                  color="red.500"
                />
              </Box>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

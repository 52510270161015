import {
  DraggableLocation,
  DraggingStyle,
  NotDraggingStyle
} from "react-beautiful-dnd"
import { stateType } from "../common/interfaces"
import { CSSProperties } from "react"
import { colorPalette } from "../common/constants"
import { characteristicsInterface } from "../common/types"

// a little function to help us with reordering the result
export const reorder = (
  list: characteristicsInterface[],
  startIndex: number,
  endIndex: number
) => {
  const removed = list.filter((a, i) => i !== startIndex)
  const start = removed.slice(0, endIndex)
  const end = removed.slice(endIndex)
  return [...start, list[startIndex], ...end]
}

/**
 * Moves an item from one list to another list.
 */
export const move = (
  source: characteristicsInterface[],
  destination: characteristicsInterface[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)
  destClone.splice(droppableDestination.index, 0, removed)
  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone
  }
}

const grid = 0.5

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  //   userSelect: "none",
  padding: `${grid * 2}em`,
  margin: `${grid}em`,

  // change background colour if dragging
  background: isDragging ? colorPalette[0] : colorPalette[0],
  border: "1px solid lightgrey",
  whiteSpace: "break-spaces",
  ...draggableStyle
})

export const getListStyle = (
  isDraggingOver: boolean,
  isSelected: boolean
): CSSProperties => ({
  background: isDraggingOver ? colorPalette[3] : "lightblue",
  // margin: `${grid}em`,
  height: isSelected ? "100%" : "min-content",
  overflow: isSelected ? "auto" : "hidden",
  width: "100%",
  padding: isSelected ? "0" : "0.5em",
  margin: isSelected ? "0.5em" : "0",

  boxShadow: "0.1875em 0.1875em 0.2em 0 rgba(0,0,0,0.5)"
})

export type isSelectedType = "selected" | "unselected"

export type categoryType = {
  selected: characteristicsInterface[]
  unselected: characteristicsInterface[]
}

export const getInitialCharacteristics = ({
  state
}: {
  state: stateType
}): categoryType => {
  const { availableCharacteristics } = state

  const emptySets: categoryType = { selected: [], unselected: [] }

  const unsorted = availableCharacteristics.reduce((acc, i) => {
    const isSelected = i.enabled ? "selected" : "unselected"
    acc[isSelected].push(i)
    return acc
  }, emptySets)

  return {
    selected: unsorted.selected,
    unselected: unsorted.unselected
  }
}

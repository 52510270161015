import React, { FC } from "react"
import { IconType } from "react-icons/lib"
import { GoPlus } from "react-icons/go"
import Classes from "./Classes/Classes"
import Characteristics from "./Characteristics/Characteristics"
import { ResultsPage } from "./Results/ResultsPage"
import LandingPage from "./LandingPage/LandingPage"
import { stateType } from "./common/interfaces"

export type buttonDirectionType = "Continue" | "Back"
type details = {
  name: string
  icon: IconType
  component: FC<any>
  modal?: string
  checkDisabled?: (state: stateType, direction: buttonDirectionType) => boolean
}

export const displayObject: details[] = [
  {
    name: "/",
    icon: GoPlus,
    component: (finalProps: any) => <LandingPage {...finalProps} />,
    checkDisabled: (state) => state.loading
  },
  {
    name: "/characteristics",
    icon: GoPlus,
    component: (finalProps: any) => <Characteristics {...finalProps} />,
    checkDisabled: (state, direction) => {
      if (direction === "Back") return false
      const enabledCharacteristics = state.availableCharacteristics.filter(
        ({ enabled }) => enabled
      )
      return enabledCharacteristics.length === 0
    }
  },
  {
    name: "/classes",
    icon: GoPlus,
    component: (finalProps: any) => <Classes {...finalProps} />
  },
  {
    name: "/results",
    icon: GoPlus,
    component: (finalProps: any) => <ResultsPage {...finalProps} />
  }
]
